import { Component, HostListener, OnInit } from '@angular/core';
import { SharedService } from './service/shared.service';
import { UploadService } from './service/upload.service';
import { MessageService } from 'primeng/api';
import { UploadFile } from './model/uploadFile';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit{
  title = 'app';
  public sidebarVisible: boolean = false
  constructor(private _sharedService: SharedService,
    private uploadService: UploadService,
    private messageService: MessageService
  ) {
    this.uploadService.getUploadStatusChanges().subscribe((uploadFile: UploadFile) => {
      if (uploadFile.status === 'successful') {
				this.messageService.add({severity: 'success', summary: 'Successo', detail: 'Caricamento file riuscito'});
			  } else if (uploadFile.status === 'failed') {
				this.messageService.add({
					severity: 'error',
					summary: 'Attenzione',
					detail: 'Caricamento file non riuscito',
				});
			  }
    });
      this.uploadService.getUploadInProgressStatus().subscribe({
        next: (resp) => {
          return;
            if (resp) {
              this.showConfirm();
            } else {
              this.onClose()
            }
        }
      })
  }
  
  visible: boolean = false;
  ngOnInit(): void {
    this._sharedService.uploadManagerVisible$.subscribe(value => {
      this.sidebarVisible = value
    })
  }
  showConfirm() {
    if (!this.visible) {
        this.messageService.add({ key: 'confirm', sticky: true, severity: 'custom' });
        this.visible = true;
    }
}
openUploadManager() {
  this._sharedService.setUplaodManagerVisible(true)
}
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.uploadService.hasPendingDownloads()) {
    console.log($event);
    $event.returnValue = 'I tuoi cambiamenti potrebbero andare persi. Sei sicuro di voler uscire?';
    }
  }

  onClose() {
    this.visible = false;
}


}

