import { Inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { UploadFile } from '../model/uploadFile';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
reconnect() {
  this.hubConnection.start()
    .then(() => console.log('SignalR reconnected successfully'))
    .catch(err => {
      console.error('SignalR reconnection failed:', err);
      setTimeout(() => this.reconnect(), 5000); // Riprova dopo 5 secondi
    });
}
  private hubConnection: signalR.HubConnection;
  public uploadProgresses: { [key: string]: UploadFile } = {};
  

  private uploadStatusSubject = new Subject<UploadFile>();
  private uploadInProgressSubject = new BehaviorSubject<boolean>(false); // Notifica se ci sono upload in corso

  hasPendingDownloads(): boolean {
    return Object.values(this.uploadProgresses).some(
      (file) => file.status !== 'successful' && file.status !== 'failed'
    );
  }

  constructor(
    @Inject('BASE_URL') baseUrl: string) {
      this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${baseUrl}uploadProgressHub`, {
    transport: signalR.HttpTransportType.WebSockets
  })
  .withAutomaticReconnect() // Abilita la riconnessione automatica
  .build();
  this.hubConnection.serverTimeoutInMilliseconds = 100000; // Default: 30 secondi
  this.hubConnection.keepAliveIntervalInMilliseconds = 30000; // Default: 15 secondi
  
// Gestisci la connessione
this.hubConnection.start()
  .then(() => console.log('SignalR connected'))
  .catch(err => {
    console.error('SignalR connection error:', err);
    setTimeout(() => this.reconnect(), 5000); // Tentativo manuale di riconnessione
  });

// Eventi per la gestione dello stato
this.hubConnection.onreconnecting((error) => {
  console.warn('SignalR reconnecting:', error);
});

this.hubConnection.onreconnected((connectionId) => {
  console.log('SignalR reconnected. Connection ID:', connectionId);
});

this.hubConnection.onclose((error) => {
  console.error('SignalR connection closed:', error);
  setTimeout(() => this.reconnect(), 5000); // Tentativo di riconnessione
});



    this.hubConnection.start().catch(err => console.error(err));

    this.hubConnection.on('UploadProgress', (data: { uploadId: string, phase: string, percentageDone: number, }) => {
      if (!this.uploadProgresses[data.uploadId]) {
        return;
      }

      const uploadFile = this.uploadProgresses[data.uploadId];

      if (data.phase === 'uploadToAspNet') {
        uploadFile.progressAspNet = data.percentageDone;
      } else if (data.phase === 'uploadToS3') {
        uploadFile.progressS3 = data.percentageDone;
        if (data.percentageDone >= 100) {
          uploadFile.status = 'successful';
        } else {
          uploadFile.status = 'uploadToS3';
        }
      } else if (data.phase === 'noSpaceS3') {
        uploadFile.status = 'failed';
        uploadFile.failedReason = 'noSpaceS3';
        uploadFile.message = "Spazio di archiviazione esaurito";
      }

      this.uploadStatusSubject.next(uploadFile);

      // Aggiorna lo stato degli upload in corso
      this.checkUploadInProgress();
    });
  }

  addUploadFile(uploadFile: UploadFile) {
    this.uploadProgresses[uploadFile.uploadId] = uploadFile;
    this.checkUploadInProgress(); // Controlla lo stato degli upload
  }

  getUploadProgress(uploadId: string): UploadFile {
    return this.uploadProgresses[uploadId];
  }

  updateUploadProgress(uploadId: string, progress: number, status: 'pending' | 'uploadToAspNet' | 'uploadToS3' | 'successful' | 'failed') {
    if (this.uploadProgresses[uploadId]) {
      this.uploadProgresses[uploadId].progressAspNet = progress;
      this.uploadProgresses[uploadId].status = status;
      this.uploadStatusSubject.next(this.uploadProgresses[uploadId]);
      this.checkUploadInProgress(); // Controlla lo stato degli upload
    }
  }

  getUploadStatusChanges(): Observable<UploadFile> {
    return this.uploadStatusSubject.asObservable();
  }

  /**
   * Controlla se ci sono upload in corso e aggiorna lo stato
   */
  private checkUploadInProgress() {
    const inProgress = this.hasPendingDownloads();
    this.uploadInProgressSubject.next(inProgress);
  }

  /**
   * Restituisce un Observable per sapere se ci sono upload in corso
   */
  getUploadInProgressStatus(): Observable<boolean> {
    return this.uploadInProgressSubject.asObservable();
  }
}