import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentiService } from '../../service/documenti.service';
import {
  AreaDocumentale,
  ColOrdinamento,
  Documento,
  DocumentoKeyValue,
  DocumentoSearch,
  KeyActive,
  KeyEtichetta,
  ModelloDocumentale,
  ModelloDocumentaleKeys,
  NomeModello,
  NomeTab,
  SezioniDocument,
  Tipo,
  TipoOrdinamento,
} from '../../model/documento';
import { ColonnaOrdinamentoPipe, NomeTabPipe, TipoChiavePipe, TipoEntitaPipe } from 'src/app/pipe/tipo-entita-pipe';
import { FormGroup } from '@angular/forms';
import * as JSZip from 'jszip';
import {
  ConfirmEventType,
  ConfirmationService,
  LazyLoadEvent,
  MessageService,
  SortEvent,
} from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Dizionario, ModelloDocumentaleCustom, Scenario, TipologiaDizionario } from 'src/app/model/scenari';
import { ScenarioService } from 'src/app/service/scenario.service';
import { CUP } from 'src/app/model/cup';
import { SpeseService } from 'src/app/service/spese.service';
import { QEService } from 'src/app/service/qe.service';
import { NavigazioneDaDocumenti, SharedService } from 'src/app/service/shared.service';
import { FontiService } from 'src/app/service/fonti.service';
import { Fonte } from 'src/app/model/fonte';
import { AnnotazioniService } from 'src/app/service/annotazioni.service';
import {
  Fase1,
  Fase2,
  Fase3,
  Fase4,
  Fase5,
  Fase5Response,
  Fasi1Response,
} from 'src/app/model/fase1';
import { Table } from 'primeng/table';
import { Ruolo, TipoPermesso, Utente } from 'src/app/model/utente';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';
import { UploadService } from 'src/app/service/upload.service';
import { delay } from 'rxjs';
import { blob } from 'stream/consumers';

interface Column {
  field: string;
  header: string;
}

@Component({
  selector: 'rbk-documenti',
  templateUrl: './rbk-documenti.html',
  styleUrls: ['./rbk-documenti.scss'],
  providers: [ConfirmationService, MessageService],
})
export class RbkDocumentiComponent implements OnInit, OnChanges {
  /**
   * E' true se c'è l'id
   */
  @Input() public isId: boolean;
  /**
   * L'id del cup di riferimento
   */
  @Input() public cupId: number;
  /**
   * Cup
   */
  @Input() public cup: CUP;
  /**
   * Ricarica i dati all'interno della Tab
   */
  @Input() public isRefreshTab: boolean;

  @Input() public showFonti: boolean;
  /**
   * Permesso in base all'utente loggato
   */
  @Input() public permesso: TipoPermesso;
  /**
   * Utente loggato
   */
  @Input() public user: Utente;
  /**
   * Indica da dove è stato chiamato il componente
   */
  @Input() public nomeTab: NomeTab;

  @Input() public refreshDocumenti: boolean;
  /**
   * La lista dei tipidizionario
   */
  @Input() public tipiDizionario: Dizionario[];
  /**
   * Evento che resetta il refreshTab per aggiornare i documenti
   */
  @Output() public onRefreshTabChange = new EventEmitter<boolean>();

  public tipiSpeseFiltro: Dizionario[];
  public tipiSpeseFromDizionario: Dizionario[];
  public tipiGiustificativiFromDizionario: Dizionario[];
  public tipiAttiFromDizionario: Dizionario[];
  public statiCup: Dizionario[] = [];
  public tipiQE: Dizionario[] = [];

  public visibleForm: boolean;

  public buttonDocumenti: boolean;

  public documentoID: number;

  /**
   * Documenti della tabella
   */
  public documenti: Documento[] = [];
  /**
   * Le chiavi associate al documento
   */
  public keys: DocumentoKeyValue[] = [];
  /**
   * Spinner caricamento Griglia
   */
  public caricamentoGrid: boolean = false;
  /**
   * Spinner caricamento dati Griglia
   */
  public spinnerLoadDataGrid: boolean = false;
  /**
   * Le chiavi filtrate
   */
  public keysFiltered: DocumentoKeyValue[];
  /**
   * La chiave selezionata in griglia
   */
  public selectedKey: DocumentoKeyValue[] = [];
  /**
   * La pipe per il tipo entità
   */
  public tipoEntitaPipe = new TipoEntitaPipe();
  /**
   * Flag per aprire/chiudere la form dei documenti
   */
  public showFormDocumenti: boolean = false;
  /**
   * Modelli selezionati per il filtraggio dei documenti
   */
  public header: string;

  public selectedModelli: NomeModello[] = [];
  /**
   * Modello selezionato per il caricamento dei documenti
   */
  public selectedModello: string;
  /**
   * Tipo selezionato se nella lista è una voce singola
   */
  public selectedTipo: string;
  /**
   * La form per caricare un file
   */
  public visualizza: boolean;

  public formGroup: FormGroup;
  /**
   * La lista dei modelli documentali
   */
  public modelli: NomeModello[] = [];

  public nomeModello: string;

  public modelliDocumentali: ModelloDocumentale[] = [];

  public modelliEsecuzione: NomeModello[] = [];
  public modelliFonteFinanziamento: NomeModello[] = [];
  public modelliAnnotazioni: NomeModello[] = [{ nomeModello: 'Annotazioni' }];
  /**
   * La lista dei modelli documentali per carica file
   * dalla tab documenti
   */
  public modelliCaricaFile: NomeModello[] = [];
  public modelliCaricaFileAnnotazioni: NomeModello[] = [
    { nomeModello: 'Annotazioni' },
    { nomeModello: 'Altro' },
  ];
  public modelliCaricaFileFonti: NomeModello[] = [
    { nomeModello: 'FonteFinanziamento' },
    { nomeModello: 'Incassato' },
    { nomeModello: 'Altro' },
  ];
  public modelliCaricaFileDocumenti: NomeModello[] = [
    { nomeModello: 'Ordine di servizio' },
    { nomeModello: 'SAL' },
    { nomeModello: 'Progetto' },
    { nomeModello: 'Corrispondenza' },
    { nomeModello: 'Delibera di Giunta' },
    { nomeModello: 'Delibera di Consiglio' },
    { nomeModello: 'Determina' },
    { nomeModello: 'Contratto' },
    { nomeModello: 'Altro' },
  ];

  public nomeModelliDocumenti: string[] = [];

  /**
   * La Lista dei tipi documento
   */
  public tipologie: Tipo[] = [];
  /**
   * Le colonne della griglia
   */
  public cols: Column[];
  /**
   * Le colonne attive della griglia
   */
  public columnsActive: Column[];
  /**
   * Se true apre il popup per cancellare un file
   */
  public isShowDialogDeleteDoc: boolean = false;
  /**
   * Area documentale in cui mi trovo
   */
  public sezione: SezioniDocument;
  public datiModelloDocumentale: ModelloDocumentaleCustom;
  /**
   * Url del documento
   */
  public urlBlob: string;
  /**
   * Se true abilita il salvataggio del file
   */
  public isEnabledLoadFile: boolean = false;
  /** 
    * Sezioni Modello Documentale
    */
  public sezioni: SezioniDocument[];

  public modelliDocumentaliDaSezioni: ModelloDocumentale[] = [];
  public eventSort: SortEvent;
  public sortOrder: any;
  public isSorted: boolean = null;
  public hasSystemModello: boolean = false;
  public dataEtichetta: string = '';
  public allKeyValueActive: string = '';
  @ViewChild('tableDocumenti1') tableDocumenti1: Table;
  @ViewChild('tableDocumenti2') tableDocumenti2: Table;
  public ordinamentoPipe = new ColonnaOrdinamentoPipe();
  public TipoPermesso = TipoPermesso;
	public Ruolo = Ruolo;
	public RuoloPipe = new RuoloPipe();
  public NomeTab = NomeTab;
  public nomeTabPipe = new NomeTabPipe();
  
  constructor(
    private _documentiService: DocumentiService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private scenarioServices: ScenarioService,
    private speseServices: SpeseService,
    private _fontiServices: FontiService,
    private _annotazioniServices: AnnotazioniService,
    private qeServices: QEService,
    private _sharedServices: SharedService,
    private uploadService: UploadService
  ) {
    this.uploadService.getUploadStatusChanges().subscribe(async (file) => {
      if (file.status === 'successful') {
        this.spinner.show();
          await delay(3000);
          this.setupGridDocumenti(null, TipoOrdinamento.Nessuno, false); 
          this.spinner.hide();
      }
    });
    
  }

  public async ngOnChanges(changes: SimpleChanges) {
    const isRefreshTabChanges = changes && changes['isRefreshTab'];
    const isRefreshDocumentiChanges = changes && changes['refreshDocumenti'];
    if (
      (isRefreshDocumentiChanges &&
        isRefreshDocumentiChanges.currentValue &&
        isRefreshDocumentiChanges.currentValue !==
          isRefreshDocumentiChanges.previousValue) ||
      (isRefreshTabChanges && isRefreshTabChanges.currentValue === true)
    ) {


    this.sezioni = await this._sharedServices.getSezioniModelloDocumentale();
    const nomeSezione = this.nomeTabPipe.transform(this.nomeTab);
    this.sezione = this.sezioni.find(s => s.nome.includes(nomeSezione));    
      await this.setupGridDocumenti();
      this.onRefreshTabChange.emit(false);
    }
  }

  public async ngOnInit() {

    this.sezioni = await this._sharedServices.getSezioniModelloDocumentale();
    const nomeSezione = this.nomeTabPipe.transform(this.nomeTab);
    this.sezione = this.sezioni.find(s => s.nome.includes(nomeSezione));    
    await this.getListaModelliDocumentaliFilter();
    this.getDizionari();
    if (this.cupId) {
      await this.setupGridDocumenti();
    }
  }

  /**
   * Recupero la lista dei modelli documentali in base alla sezione in cui mi trovo
   */
  public async getListaModelliDocumentaliFilter () {
    let modelliSezione: ModelloDocumentale[];
    const modelliDoc = await this._documentiService.getAllModelliDocumentali().toPromise();
    if (modelliDoc && modelliDoc.length > 0) {
      this._sharedServices.setModelliDocumentali(modelliDoc);
      switch (this.nomeTab) {
        case NomeTab.Documenti || NomeTab.Progetto || NomeTab.Gara:
          this.cols = [
            { field: 'nomeModello', header: 'Modello' },
            { field: 'key2_Value', header: 'Data' },
          ];
          break;
        case NomeTab.Fonte:
          this.cols = [
            { field: 'key3_Value', header: 'Numero' },
            { field: 'key5_Value', header: 'Descrizione' },
            { field: 'key6_Value', header: 'Importo' },
            { field: 'key7_Value', header: 'Ente' },
          ];
          break;
        default:
          break;
      }
      this.columnsActive = this.cols;
      const nomeSezione = this.nomeTabPipe.transform(this.nomeTab);
      this.sezione = this.sezioni.find(s => s.nome.includes(nomeSezione));
      if (this.sezione) {
        modelliSezione = modelliDoc.filter(m => m.modelliSezione.find(modSezione => modSezione.idSezione === this.sezione.id));
        if (modelliSezione && modelliSezione.length > 0) {
          modelliSezione.forEach(modello => {
            if (modello && modello.nome) {
              let nomeModello: NomeModello = { nomeModello: modello.nome };
              this.modelli.push(nomeModello);
            }
          });
        }
      }
    }
  }
  

  async getDizionari() {
      this.tipiSpeseFromDizionario = this.tipiDizionario && this.tipiDizionario.filter(d => d.categoria === TipologiaDizionario.Spesa_Tipo) || [];
      this.tipiAttiFromDizionario = this.tipiDizionario && this.tipiDizionario.filter(d => d.categoria === TipologiaDizionario.Atto) || [];
      this.tipiGiustificativiFromDizionario = this.tipiDizionario && this.tipiDizionario.filter(d => d.categoria === TipologiaDizionario.Giustificativo_Tipo) || [];
      this.statiCup = this.tipiDizionario && this.tipiDizionario.filter(d => d.categoria === TipologiaDizionario.CUP_Stato) || [];
      this.tipiQE = this.tipiDizionario && this.tipiDizionario.filter(d => d.categoria === TipologiaDizionario.QEBozza_Tipo) || [];
    }

  public getWidth(col: Column) {
    let width = '';
    if (col && col.header === 'Descrizione') {
      width = '20%';
    }
    if (col && col.header === 'Numero') {
      width = '9%';
    }
    return width;
  }

  public getWhiteSpace(col: Column) {
    let whiteSpace = 'nowrap';
    if (col && col.header === 'Descrizione') {
      whiteSpace = 'pre-line !important';
    }
    return whiteSpace;
  }

  /**
   * Recupera la lista dei documenti
   */
  protected async setupGridDocumenti(orderColumn?: ColOrdinamento, ordinamento = TipoOrdinamento.Nessuno, showLoader = true) {
    this.keys = [];

    let documentoSearch = new DocumentoSearch();
    documentoSearch.cupId = this.cupId;
    documentoSearch.entityId = null;
    documentoSearch.colonnaOrdinamento = orderColumn;
    documentoSearch.ordinamento = ordinamento;

    if (this.cupId) {
      this.caricamentoGrid = showLoader;

      if (this.sezioni && this.sezioni.length > 0) {
        const nomeTab = this.nomeTabPipe.transform(this.nomeTab);
        documentoSearch.sezione = this.sezioni && this.sezioni.find(s => s.nome.includes(nomeTab));
      }
      if(documentoSearch.sezione) {
        const documenti = await this._documentiService.filterDocumento(documentoSearch, this.sezioni).toPromise();
        if (documenti) {
          this.keysFiltered = [];
          
        const modelli = this._sharedServices.getModelliDocumentali();
        const modelliSezione = this.getModelliBySezione(modelli);
          documenti.forEach((documento) => {
            if (documento.keys) {
              if (modelliSezione.find(m => m.id === documento.modelloID) ) 
              {
              let keyValues: DocumentoKeyValue;
              keyValues = documento.keys;
              keyValues.idDocumento = documento.id;
              keyValues.nomeFile = documento.nomeFile;
              keyValues.entityId = documento.entityID;
              keyValues.modelloId = documento.modelloID;
              keyValues.nomeModello = documento.modello.nome;
              keyValues.allKeyValueActive = this.createCampoChiaveValore(keyValues, documento.modello);
              keyValues.protocollo = documento.protocollo;
              const regexHtml = /<[^>]*>/g;
              keyValues.allKeyValueTooltip = keyValues.allKeyValueActive.replace(regexHtml, '');
              const documentoFind = this.keys.find((k) => k.id === keyValues.id);
              if (!documentoFind) {
                this.keys.push(keyValues);
              }
            }
          }
          });
          this.keysFiltered = this.keys;
        }

        const dati = this._sharedServices.getParametriNavigaDaDocumentiArchviazione();
        if ((dati?.sezione === 'Fonte di Finanziamento' && dati?.sottoSezione === 'Documento Fonti') || dati?.sezione === 'Documento') {
          this.showDialogForm();
        }
      }
      this.caricamentoGrid = false;
    }
  }

 async onChangeItemDropdown(event) {
    if (event.item === 'nomeModello') {
      let nomeModello: string =
      this.datiModelloDocumentale?.formGroup?.value.nomeModello;
      this.onChangeModello(nomeModello);
    } else if(event.item === 'nomeModelloDocumentale') {
      const nomeModello = this.datiModelloDocumentale?.formGroup?.value.nomeModelloDocumentale;
      const keysModelloDocumentale = this.modelliDocumentaliDaSezioni.find(x => x.nome === nomeModello)?.keysModelloDocumentale;
      this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.ModelloDocumentale, keysModelloDocumentale);
      this.datiModelloDocumentale.idCup = this.cupId;
      this.datiModelloDocumentale.formGroup = this.scenarioServices.getFormGroup(Scenario.ModelloDocumentale, null, keysModelloDocumentale);
      this.datiModelloDocumentale.formGroup.get('nomeModello').setValue(nomeModello);
     }
  }

  /**
   * Recupero i modelli documentali in base alla sezione di riferimento
   * @param modelliDocumentali modelli documentali lista
   * @returns i modelli documentali in base alla sezione
   */
  public getModelliBySezione(modelliDocumentali: ModelloDocumentale[]) {
    let modelliSezione: ModelloDocumentale[] = [];
    if (modelliDocumentali && this.sezione) {
      const modelliBySezione = modelliDocumentali.filter(m => m.modelliSezione.find(modSezione => modSezione.idSezione === this.sezione.id));
      if (modelliBySezione && modelliBySezione.length > 0) {
        modelliBySezione.forEach(modelloSezione => {
          if (modelloSezione) {
            modelliSezione.push(modelloSezione);
          }
        }); 
      } 
    }
    return modelliSezione;
  }

  async caricaDocumenti(){
    this.spinner.show();
    this.modelliDocumentali = [];
    this.header = 'Carica File';
    this.nomeModello = undefined;
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.ModelloDocumentaleStart, this.sezioni);
    const modello = this.datiModelloDocumentale?.DatiForm?.find((x) => x.key === 'nomeModelloDocumentale');
    const modelliDocumentali = this._sharedServices.getModelliDocumentali();
    if(modelliDocumentali && this.sezione) {
      const modelliSezione = this.getModelliBySezione(modelliDocumentali);
      if (modelliSezione && modelliSezione.length > 0) {
        this.modelliDocumentaliDaSezioni = modelliSezione;
        modello.lista = this.modelliDocumentaliDaSezioni.filter(m => m.attivo && !m.systemModello);
      } 
    }
    this.datiModelloDocumentale.formGroup = this.scenarioServices.getFormGroup(Scenario.ModelloDocumentaleStart);
    this.buttonDocumenti = true;
    this.visibleForm = true;
    this.spinner.hide();
  }

  /**
   * Apre la form per cricare il file nello scenario specificato
   */
  public async showDialogForm(key?: any, dati?: NavigazioneDaDocumenti) {
    this.spinner.show();
    this.visualizza = false;
    let formGroup;
    let entityID = 0;
    let scenarioDoc: Scenario;
    if (key) {
      this.buttonDocumenti = false;
      this.documentoID = key.idDocumento;
      entityID = key.entityId;
      this.nomeModello = key.key1_Value;
      let key2_Value = key.key2_Value;
      let codCup = key.key3_Value;
      let descEsecuzione = key.key4_Value;
      let key5_Value: string = key.key5_Value;
      let key6_Value = key.key6_Value;
      let key7_Value = key.key7_Value;
      let key8_Value = key.key8_Value;
      let key9_Value = key.key9_Value;
      let key10_Value = key.key10_Value;
      let nomeFile = key.nomeFile;

      let qe;
      scenarioDoc = this.scenarioServices.getScenarioDaDocumenti(this.nomeModello, entityID);

      let modelloKeysView: ModelloDocumentaleKeys = null;
      if (scenarioDoc === Scenario.Documento) {
        const modelli = this._sharedServices.getModelliDocumentali();
        const modelliSezione = this.getModelliBySezione(modelli);
        if (modelliSezione && modelliSezione.length > 0) {
          this.nomeModello = modelliSezione.find(m => m.id === key?.modelloId)?.nome;
          const isSystemModello = modelliSezione.find(m => m.id === key.modelloId)?.systemModello;
          if(isSystemModello) {
            this.hasSystemModello = true;
          } else {
            this.hasSystemModello = false;
          }
          this.nomeModelliDocumenti = modelliSezione.map(m => m.nome);
          modelloKeysView = modelliSezione.find(m => m.id === key?.modelloId)?.keysModelloDocumentale;
        }
      }
      this.header = this.nomeModello;
      let ordinativiDiSpesa: Fase1[];
      let fase1Riferimenti: Fase1[] | Fase1;
      let fase2: Fase2;
      let fase3: Fase3;
      let fase4: Fase4;
      if (scenarioDoc !== Scenario.Documento) {
        if (scenarioDoc === Scenario.QuadroEconomico) {
          const qeHistories = this.cup?.qEs.find(qe => qe.histories);
          const qeFind = qeHistories.histories.find(q => q.id === entityID);
          qe = qeFind;
        } else if (scenarioDoc > Scenario.Cup && scenarioDoc < Scenario.Annotazione) {
          const response = await this.speseServices.getAllFasiByCupId(this.cupId).toPromise();
          if (response && response.success) {
            ordinativiDiSpesa = response.dtos;
          } else {
            console.log('500');
          }
        }
      }
      this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(scenarioDoc, modelloKeysView);
      formGroup = this.scenarioServices.getFormGroup(scenarioDoc, key, modelloKeysView);
      formGroup.disable();

      if (scenarioDoc === Scenario.Cup) {
        this.datiModelloDocumentale.DatiForm[5].lista = this.statiCup.filter(x => x.attivo);
        formGroup.setValue({
          id: this.cup?.id,
          codice: this.cup?.codice,
          titolo: this.cup?.titolo,
          indirizzo: this.cup?.indirizzo,
          importo: this.cup?.importo,
          descrizione: this.cup?.descrizione,
          stato: this.cup?.stato,
          allegato: this.cup?.allegato || null,
        });      
      } else if (scenarioDoc === Scenario.Spesa) {
        let spesa = ordinativiDiSpesa.find((x) => x.id === entityID);
        let qeFlatItems = [];
        const response = await this.qeServices.getFlatQEItems(this.cup?.id).toPromise();
        if (response && response.success) {
          qeFlatItems = response.dtos || [];
        } else {
          console.log('500');
        }
      
        formGroup.setValue({
          cig: spesa?.cig || null,
          id: spesa?.id,
          tipo: spesa?.tipo,
          atto: spesa?.atto,
          numero: spesa?.numero,
          beneficiario: spesa?.beneficiario,
          data: new Date(spesa?.data),
          descrizione: spesa?.descrizione,
          pIvaCodiceFiscale: spesa?.pIvaCodiceFiscale,
          importoNetto: spesa?.importoNetto,
          importoIVA: spesa?.importoIVA,
          importoCassa: spesa?.importoCassa,
          qeItemImportoNettoIndex: spesa?.qeItemImportoNetto?.index ?? null,
          qeItemImportoNettoCompositeId:
            spesa?.qeItemImportoNetto?.compositeId ?? null,
          qeItemImportoNettoSubIndex:
            spesa?.qeItemImportoNetto?.subIndex ?? null,
          qeItemImportoNettoSectionIndex:
            spesa?.qeItemImportoNetto?.qeSectionIndex ?? null,
          qeItemImportoNettoSectionQEMainId:
            spesa?.qeItemImportoNetto?.qeSectionQEMainId ?? null,
          qeItemIVACompositeId: spesa?.qeItemIVA?.compositeId ?? null,
          qeItemIVAIndex: spesa?.qeItemIVA?.index ?? null,
          qeItemIVASubIndex: spesa?.qeItemIVA?.subIndex ?? null,
          qeItemIVASectionIndex: spesa?.qeItemIVA?.qeSectionIndex ?? null,
          qeItemIVASectionQEMainId: spesa?.qeItemIVA?.qeSectionQEMainId ?? null,
          qeItemCassaIndex: spesa?.qeItemCassa?.index ?? null,
          qeItemCassaCompositeId: spesa?.qeItemCassa?.compositeId ?? null,
          qeItemCassaSubIndex: spesa?.qeItemCassa?.subIndex ?? null,
          qeItemCassaSectionIndex: spesa?.qeItemCassa?.qeSectionIndex ?? null,
          qeItemCassaSectionQEMainId:
            spesa?.qeItemCassa?.qeSectionQEMainId ?? null,
          cupId: this.cupId,
          importoTotale:
            spesa?.importoNetto + spesa?.importoIVA + spesa?.importoCassa,
        });

        this.datiModelloDocumentale.DatiForm[1].lista =  this.tipiSpeseFromDizionario.filter(x => x.attivo);
        this.datiModelloDocumentale.DatiForm[2].lista = this.tipiAttiFromDizionario.filter(x => x.attivo);
        this.datiModelloDocumentale.DatiForm[10].lista = qeFlatItems;
        this.datiModelloDocumentale.DatiForm[12].lista = qeFlatItems;
        this.datiModelloDocumentale.DatiForm[14].lista = qeFlatItems;

      } else if (scenarioDoc === Scenario.QuadroEconomico) {
        this.datiModelloDocumentale.DatiForm[0].lista = this.tipiQE.filter(x => x.attivo);
        this.datiModelloDocumentale.DatiForm[1].lista = this.tipiAttiFromDizionario.filter(x => x.attivo);
        formGroup.setValue({
          cupId: qe?.cupId,
          id: qe?.detail.id,
          tipo: qe?.detail.tipo,
          qeMainId: qe?.detail.qeMainId,
          atto: qe?.detail.atto,
          numero: qe?.detail.numero,
          data: new Date(qe?.detail.data),
          descrizione: qe?.detail.descrizione,
          allegato: qe?.detail.allegato || false,
        });
      } else if (scenarioDoc === Scenario.Giustificativo) {
        const response = await this.speseServices.getFase2(entityID).toPromise();
        if(response && response.success && response.dto){
          const giustificativo = response.dto;
          formGroup.setValue({
            id: entityID,
            fase1Id: giustificativo?.fase1Id,
            tipo: giustificativo?.tipo,
            numero: giustificativo?.numero,
            data: new Date(giustificativo?.data),
            importoNetto: giustificativo?.importoNetto,
            iva: giustificativo?.iva,
            cassa: giustificativo?.cassa,
            descrizione: descEsecuzione || null,
            beneficiario: giustificativo?.beneficiario || null,
            pIvaCodiceFiscale: giustificativo?.pIvaCodiceFiscale || null,
            totale: (giustificativo?.importoNetto + giustificativo?.iva + giustificativo?.cassa),
          });
          formGroup.disable();
          
        this.datiModelloDocumentale.DatiForm[0].lista = this.tipiGiustificativiFromDizionario;
        } else {
          this.scenarioServices.showErrorMessage('Errore nel caricamento del Documento')
        }
      } else if (scenarioDoc === Scenario.Liquidazione) {
        let responseLiquidazione = await this.speseServices.getFase3(entityID).toPromise();
        if(responseLiquidazione && responseLiquidazione.dto) {
          let liquidazione = responseLiquidazione.dto;
          formGroup.setValue({
            id: liquidazione.id,
            fase2Id: liquidazione.fase2Id,
            numero: liquidazione.numero,
            data: new Date(liquidazione.data),
            importoNetto: liquidazione.importoNetto,
            iva: liquidazione.iva,
            cassa: liquidazione.cassa,
            totale:
              liquidazione.importoNetto + liquidazione.iva + liquidazione.cassa,
          });
          const giustificativo = await this.speseServices.rifFase2(responseLiquidazione.dto.idFase1).toPromise();
          if(giustificativo && giustificativo.dtos) {
            this.datiModelloDocumentale.DatiForm[2].lista = giustificativo.dtos;
          }
          formGroup.disable();
        }
      } else if (scenarioDoc === Scenario.Mandato) {
        let mandato: Fase4;
        const response = await this.speseServices.getFase4(entityID).toPromise();
        if (response && response.success) {
          mandato = response.dto;
        } else {
          console.log('500');
        }

        formGroup.setValue({
          id: mandato.id,
          fase3Id: mandato.fase3Id,
          numero: mandato.numero,
          data: new Date(mandato.data),
          importo: mandato.importo,
          descrizione: mandato.descrizione,
        });
        const liquidazione = await this.speseServices.rifFase3(response.dto.idFase1).toPromise();
        if(liquidazione && liquidazione.dtos) {
          this.datiModelloDocumentale.DatiForm[4].lista = liquidazione.dtos;
        }
      } else if (scenarioDoc === Scenario.Quietanza) {
        let quietanza: Fase5;
        const response = await this.speseServices.getFase5(entityID).toPromise();
        if (response && response.success) {
          quietanza = response.dto;
        } else {
          console.log('500');
        }
        console.log('Q ', quietanza);

        formGroup.setValue({
          id: quietanza.id,
          fase4Id: quietanza.fase4Id,
          attachment: null,
        });
        const mandato = await this.speseServices.rifFase4(response.dto.idFase1).toPromise();
        if(mandato && mandato.dtos) {
          this.datiModelloDocumentale.DatiForm[0].lista = mandato.dtos;
        }
      } else if (scenarioDoc === Scenario.Fonte && this.cupId) {
        let fonte: Fonte;
        formGroup = this.scenarioServices.getFormGroup(Scenario.Fonte);
        this.datiModelloDocumentale =
          this.scenarioServices.getModelloDocumentaleCustom(Scenario.Fonte);
        this.spinner.show();
        const fontiAttuali = await this._fontiServices
          .getFontiFinanziamentoAttuali(this.cupId)
          .toPromise();
        if (fontiAttuali && fontiAttuali.success) {
          const idDocumneto = entityID ? entityID : this.documentoID;
          fonte = fontiAttuali.dtos.find((fonte) => fonte.id === idDocumneto);
          this.datiModelloDocumentale.entityID = idDocumneto;

          formGroup.setValue({
            ente: fonte?.ente || '',
            atto: fonte?.atto,
            numero: fonte?.numero,
            del: new Date(fonte?.del),
            descrizione: fonte?.descrizione,
            importoFinanziamento: fonte?.importoFinanziamento,
          });
          formGroup.disable();
        } else {
          console.log('500');
        }
        this.spinner.hide();
      } else if (scenarioDoc === Scenario.Incasso && this.cupId) {
        let incasso;
        formGroup = this.scenarioServices.getFormGroup(Scenario.Incasso);
        this.datiModelloDocumentale =
          this.scenarioServices.getModelloDocumentaleCustom(Scenario.Incasso);
        this.spinner.show();
        const incassi = await this._fontiServices
          .getIncassi(this.cupId)
          .toPromise();
        if (incassi && incassi.success) {
          incasso = incassi.dtos.find((incasso) => incasso.id === entityID);
          this.datiModelloDocumentale.entityID = incasso?.id;

          formGroup.setValue({
            ente: '',
            atto: incasso?.atto,
            numero: incasso?.numero,
            del: new Date(incasso?.del),
            descrizione: incasso?.descrizione,
            importoIncassato: incasso?.importoIncassato,
            fonteFinanziamentoID: incasso?.fonteFinanziamentoID,
          });
          formGroup.disable();
        } else {
          console.log('500');
        }
        this.spinner.hide();
      } else if (scenarioDoc === Scenario.Annotazione && this.cupId) {
        let annotazione;
        formGroup = this.scenarioServices.getFormGroup(Scenario.Annotazione);
        this.datiModelloDocumentale =
          this.scenarioServices.getModelloDocumentaleCustom(
            Scenario.Annotazione
          );
        this.spinner.show();
        const response = await this._annotazioniServices
          .getAnnotazioniByCup(this.cupId)
          .toPromise();
        if (response && response.success) {
          const idDocumneto = entityID ? entityID : this.documentoID;
          annotazione = response.dtos.find(
            (annotazione) => annotazione.id === idDocumneto
          );
          this.datiModelloDocumentale.entityID = annotazione?.id;
          formGroup.setValue({
            id: annotazione.id,
            cupId: annotazione.cupId,
            descrizione: annotazione.descrizione,
            data: new Date(annotazione.data),
            nota: annotazione.nota,
            allegato: null,
          });
          formGroup.disable();
        } else {
          console.log('500');
        }

        this.spinner.hide();
      }

      if (this.datiModelloDocumentale) {
        this.datiModelloDocumentale.formGroup = formGroup;
        this.datiModelloDocumentale.cup = this.cup;
        this.datiModelloDocumentale.idCup = this.cup?.id;
        this.datiModelloDocumentale.entityID = entityID;
        this.datiModelloDocumentale.isEdit = true;
        this.datiModelloDocumentale.documentoID = this.documentoID;
        this.datiModelloDocumentale.entityTipo =
        this.scenarioServices.getTipologia(this.nomeModello, formGroup);
        // this.datiModelloDocumentale.DatiForm[5].lista = this.scenarioServices.stati;
      }
    } else {
      this.buttonDocumenti = true;
      if (this.buttonDocumenti) {
        this.visualizza = true;
      }

      this.header = 'Carica File';
      this.nomeModello = undefined;
      this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.ModelloDocumentaleStart, this.sezioni);

      if (this.datiModelloDocumentale) {
        this.datiModelloDocumentale.formGroup = this.scenarioServices.getFormGroup(Scenario.ModelloDocumentaleStart);
        this.datiModelloDocumentale.cup = this.cup;
        this.datiModelloDocumentale.idCup = this.cup?.id;
        this.datiModelloDocumentale.isEdit = false;
        this.datiModelloDocumentale.entityID = 0;
        this.datiModelloDocumentale.entityTipo = undefined;
        this.datiModelloDocumentale.documentoID = this.documentoID;
        const modelliDocumentali = this._sharedServices.getModelliDocumentali();
        const modelliSezione = this.getModelliBySezione(modelliDocumentali);
        if (modelliSezione && modelliSezione.length > 0) {
          this.modelliDocumentaliDaSezioni = modelliSezione;
          this.nomeModelliDocumenti = modelliSezione.map(m => m.nome);
          this.datiModelloDocumentale.DatiForm[0].lista = modelliSezione.filter(m => m.attivo && !m.systemModello);
        }
        this.datiModelloDocumentale.isFrom = Scenario.ModelloDocumentaleStart;
      }
    }
  
    if (!this.visualizza) {
      this.visualizza = this.nomeModelliDocumenti.includes(this.nomeModello);
    }

    this.spinner.hide();
    this.visibleForm = true;
  }

  /**
   * Chiude la modale per il caricamento del documento nello scenario specificato
   */
  async closeDialog(event) {
    this.visibleForm = false;
    if(event){
      await this.setupGridDocumenti();
    } 
  }

  /**
   * Chiude la form di caricamento del file
   */
  public async onCloseFormCaricaFile() {
    this.showFormDocumenti = false;
    this.selectedModello = '';
    this.selectedTipo = '';
    this.urlBlob = '';
    this.documentoID = undefined;
    this.visibleForm = false;
  }

  /**
   * Al seleziona del modello
   */
  public async onChangeModello(modello: string, form?: FormGroup) {
    switch (modello) {
      case 'Ordine di servizio':
        this.tipologie = [{ tipo: 'Ordine Di Servizio' }];
        break;
      case 'SAL':
        this.tipologie = [{ tipo: 'SAL' }];
        break;
      case 'Progetto':
        this.tipologie = [
          { tipo: 'Progetto Definitivo' },
          { tipo: 'Progetto Esecutivo' },
          { tipo: 'Progetto Integrato' },
        ];
        break;
      case 'Corrispondenza':
        this.tipologie = [{ tipo: 'Entrata' }, { tipo: 'Uscita' }];
        break;
      case 'Delibera di Giunta':
        this.tipologie = [{ tipo: ' Delibera di Giunta' }];
        break;
      case 'Delibera di Consiglio':
        this.tipologie = [{ tipo: ' Delibera di Consiglio' }];
        break;
      case 'Determina':
        this.tipologie = [{ tipo: 'Determina' }];
        break;
      case 'Contratto':
        this.tipologie = [{ tipo: 'Contratto' }];
        break;
      case 'Altro':
        this.tipologie = [{ tipo: 'Altro' }];
        break;
      case 'FonteFinanziamento':
        this.tipologie = [{ tipo: 'FonteFinanziamento' }];
        break;
      case 'Incassato':
        this.tipologie = [{ tipo: 'Incassato' }];
        break;
      default:
        break;
    }

    if (this.datiModelloDocumentale?.formGroup) {
      this.datiModelloDocumentale.DatiForm[1].lista = this.tipologie;
      if (this.tipologie?.length === 1) {
        this.datiModelloDocumentale.formGroup.get('tipologia').setValue(this.tipologie[0].tipo);
      }

    }
  }

  public async anteprimaFile(file) {
    const buffer = await file.arrayBuffer();
    const array = new Uint8Array(buffer);
    this.urlBlob = URL.createObjectURL(new Blob([array], { type: file.type }));
  }

  public downloadFile(documentoId: number, nomeFile: string) {
    this.spinner.show()
    this._documentiService.getUrlDocumento(documentoId).subscribe(
      { next: (url) => {
      const index = nomeFile.indexOf('.');
      const mediaType = nomeFile.substring(index + 1, nomeFile.length);

      if (url) {
        const a = document.createElement('a'); // Crea un elemento <a>
        document.body.appendChild(a); // Aggiungi temporaneamente al body
        a.href = url.url; // Assegna l'URL ricevuto dal servizio
        a.download = nomeFile || 'download'; // Imposta il nome del file
        //a.target = '_blank'; // Facoltativo: apre il link in una nuova finestra (per sicurezza)
        a.click(); // Simula il clic sull'elemento <a>
        
        document.body.removeChild(a); // Rimuovi l'elemento <a> dal DOM
        this.spinner.hide()
      }
    },
    error: (err) => {
      console.error('Errore nel recupero dell\'URL del documento:', err);
      this.spinner.hide()
    }
    }); 
  }

  /**
   * Filtra la griglia in base ai modelli selezionati
   * @param selectedModelli  modelli selezionati nel filtro
   */
  public async filterBrowse(selectedModelli: NomeModello[], orderColumn?: ColOrdinamento, ordinamento = TipoOrdinamento.Nessuno) {
    let filterDocumento = new DocumentoSearch();
    let modelliIds: number[] = [];
    let columnFilter: Column[] = [];
    filterDocumento.cupId = this.cupId;
    const modelliselect = selectedModelli && selectedModelli.length > 0 ? selectedModelli : this.selectedModelli;
    if (modelliselect && modelliselect.length > 0) {
      this.caricamentoGrid = true;
      modelliIds = await this.getModelliIds(modelliselect);
      if (modelliselect.length === 1) {
        columnFilter = await this.modelKeyAttivi(modelliIds);
        this.columnsActive = [];
        this.columnsActive = columnFilter;
      }
      filterDocumento.modelloIDs = modelliIds;
      filterDocumento.sezione = this.sezione;
      filterDocumento.colonnaOrdinamento = orderColumn;
      filterDocumento.ordinamento = ordinamento;
      const resultFilteredBrowse = await this._documentiService.filterDocumento(filterDocumento, this.sezioni).toPromise();
      if (resultFilteredBrowse) {
        this.keysFiltered = [];
        resultFilteredBrowse.forEach((documento) => {
          if (documento.keys) {
            let keyValues: DocumentoKeyValue;
            keyValues = documento.keys;
            keyValues.idDocumento = documento.id;
            keyValues.nomeFile = documento.nomeFile;
            keyValues.modelloId = documento.modelloID;
            keyValues.nomeModello = documento.modello.nome;
            keyValues.entityId = documento.entityID;
            keyValues.nomeModello = documento.modello.nome;
            keyValues.protocollo = documento.protocollo;
            if (modelliselect.length > 1) {
              keyValues.allKeyValueActive = this.createCampoChiaveValore(keyValues, documento.modello);
              const regexHtml = /<[^>]*>/g;
              keyValues.allKeyValueTooltip = keyValues.allKeyValueActive.replace(regexHtml, '');
            }
            const documentoFind = this.keys.find((k) => k.id === keyValues.id);
            if (documentoFind) {
              this.keysFiltered.push(keyValues);
            }
          }
        });
        this.caricamentoGrid = false;
      }
      
    } else {
      this.columnsActive = this.cols;
      this.isSorted = null;
      await this.setupGridDocumenti(orderColumn, ordinamento);
    }
  }

  public resetTableSort() {
    if(this.tableDocumenti1) {
      this.tableDocumenti1.reset();
    } else {
      this.tableDocumenti2.reset();
    }
  }

  /**
   * Pulisce il dropdown dei filtri
   */
  public async clearFilter() {
    this.selectedModelli = [];
    this.columnsActive = [];
    this.keysFiltered = [];
    this.isSorted = null;
    this.sortOrder = undefined;
    this.resetTableSort();
    await this.setupGridDocumenti();
  }

  /**
   * Al seleziona del modello
   */
  public async modelKeyAttivi(modelliIds: number[]) {
    let columnFilter: Column[] = [];
    let modello: ModelloDocumentale;
    const modelliDocumentali = this._sharedServices.getModelliDocumentali();
    const modelliSezione = this.getModelliBySezione(modelliDocumentali);
    if (modelliSezione && modelliSezione.length > 0) {
      modello = modelliSezione.find(m => m.id === modelliIds[0]); 
    }
    this.dataEtichetta = modello?.keysModelloDocumentale?.key2_Etichetta;
    columnFilter = this.createColumnFilter(modello.keysModelloDocumentale);
    return columnFilter;
  }

  public createColumnFilter(keysModelloDocumentale: ModelloDocumentaleKeys){
    let columnFilter: Column[] = [];
    for(let i = 1; i <= 10; i++){
      const key_Value = `key${i}_Value`;
      const keyEtichetta = `key${i}_Etichetta`;
      const keyActive = `key${i}_Attivo`;
  
      if (keysModelloDocumentale[keyActive] && key_Value !== 'key2_Value') {
        const column: Column = {
          field: key_Value,
          header: keysModelloDocumentale[keyEtichetta]
        }
        columnFilter.push(column);
      }
    }
    return columnFilter;
  }

  public createCampoChiaveValore(keyValue: DocumentoKeyValue, modello: ModelloDocumentale) {
    let keyValueAll: string = '';
    for(let i = 1; i <= 10; i++){
      const key_Value = `key${i}_Value`;
      const keyEtichetta = `key${i}_Etichetta`;
      const keyActive = `key${i}_Attivo`;
      const key_Tipo = `key${i}_Tipo`;

      const tipoChiave = new TipoChiavePipe().transform(modello.keysModelloDocumentale[key_Tipo]);
      let keyValuePipe = '';
      if (tipoChiave === 'Valuta') {
        keyValuePipe = new CurrencyPipe('it-IT').transform(keyValue[key_Value], 'EUR');
      } else if (tipoChiave === 'Data' || tipoChiave === 'DataOra') {
        keyValuePipe = new DatePipe('it-IT').transform(keyValue[key_Value], 'dd/MM/yy');
      }
  
      if (modello.keysModelloDocumentale[keyActive] && keyValue[key_Value] && key_Value !== 'key2_Value') {
        let value_Key = '';
        keyValuePipe ? value_Key = keyValuePipe : value_Key = keyValue[key_Value];
        keyValueAll += `<strong>${modello.keysModelloDocumentale[keyEtichetta]}:</strong> ${value_Key} <br/>`;
      }
    }
    return keyValueAll;
  }

  public createCampiForPDF(keyValue: DocumentoKeyValue, columnActive: Column[]) {
    let keyActiveValue: any = {
      nomeModello: keyValue.nomeModello,
      key2_Value: new DatePipe('en-US').transform(keyValue.key2_Value, 'dd/MM/yy'), 
    };
    for(let i = 1; i <= 10; i++){
      const key_Value = `key${i}_Value`;
  
      const col = columnActive.find(col => col.field === key_Value);
      if (col && col.field === key_Value) {
        keyActiveValue[`key${i}_Value`] = keyValue[key_Value];
      }
    }
    keyActiveValue['nomeFile'] = keyValue.nomeFile;
    return keyActiveValue;
  }

  /**
   * Restituisce i valori checkati nella browse
   * @param key l'associazione key/value
   */
  public selectedFromZip(keys: DocumentoKeyValue[]) {
    if(keys) {
      keys.forEach(key => {
        this.selectedKey.push(key);   
      });
    }
  }

  /**
   * Scarica il file zip dei documenti selezionati
   */
  public async scaricaFileZip() {
    this.spinner.show();
    this._documentiService.getZip(this.cupId).subscribe({
      next: (blob) =>{
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = `CUP-${this.cupId}.zip`; 
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        this.spinner.hide();
      },
      error: (err) => {
        console.error('Errore nel recupero del file zip:', err);
        this.spinner.hide();
      }
    })
  }

  /**
   * Cancella il documento selezionato
   */
  public async deleteFile(documentoId: number) {
    this.spinner.show();
    const resultDelete = await this._documentiService
      .deleteDocumento(documentoId)
      .toPromise();
    if (resultDelete) {
      this.messageService.add({
        severity: 'success',
        summary: 'Successo',
        detail: 'Il file è stato cancellato con successo!',
      });
      await this.setupGridDocumenti();

      this.spinner.hide();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Attenzione',
        detail: 'Non è stato possibile cancellare il file',
      });

      this.spinner.hide();
    }
  }

  /**
   * Crea il pdf della lista dei documenti
   */
  public createPdfDocumenti() {
    import('jspdf').then(async (jsPDF) => {
      const doc = new jsPDF.default('l', 'px', 'a4');
      const rows = [];
      if(this.tableDocumenti1) {
        this.keysFiltered.map(function (x) {
          rows.push({
            nomeModello: x.nomeModello,
            protocollo: x.protocollo,
            key2_Value: new DatePipe('en-US').transform(x.key2_Value, 'dd/MM/yy'),
            allKeyValueTooltip: x.allKeyValueTooltip,
            nomeFile: x.nomeFile,
          });
        });
        (doc as any).autoTable({
          columns: [
            { header: 'Modello', dataKey: 'nomeModello' },
            { header: 'Progressivo', dataKey: 'protocollo' },
            { header: 'Data', dataKey: 'key2_Value' },
            { header: 'Chiave/Valore', dataKey: 'allKeyValueTooltip' },
            { header: 'Nome File', dataKey: 'nomeFile' },
          ],
          body: rows,
          margin: { top: 35 },
          didDrawPage: function (data) {
            doc.text('ELENCO DOCUMENTI', 250, 20);
          },
          columnStyles: {
            key2_Value: { cellWidth: 40 },
            key3_Value: { cellWidth: 50 },
            nomeFile: { cellWidth: 80 },
          },
          didParseCell: (data) => {
            if (data.cell) {
              data.cell.styles.valign = 'middle';
            }
          },
        });
      } else {
        const modelliIds = this.keysFiltered.map(k => k.modelloId);
        let column: any[] = [];
        let columnActive: Column[] = [];
        const columnNomeFile: any = { header: 'Nome File', dataKey: 'nomeFile'};
        column = [
          {header: 'Modello', dataKey: 'nomeModello'},
          {header: 'Progressivo', dataKey: 'protocollo'},
          {header: this.dataEtichetta, dataKey: 'key2_Value',},
        ];
        if(modelliIds) {
          columnActive = await this.modelKeyAttivi(modelliIds);
          columnActive.forEach(col => {
            column.push({header: col.header, dataKey: col.field});
          });
        }
        column.push(columnNomeFile);
        this.keysFiltered.forEach(key => {
          const row = this.createCampiForPDF(key, columnActive);
          rows.push(row);
        });
        (doc as any).autoTable({
          columns: column,
          body: rows,
          margin: { top: 35 },
          didDrawPage: function (data) {
            doc.text('ELENCO DOCUMENTI', 250, 20);
          },
          columnStyles: {
            key2_Value: { cellWidth: 40 },
            key3_Value: { cellWidth: 50 },
            nomeFile: { cellWidth: 80 },
          },
          didParseCell: (data) => {
            if (data.cell) {
              data.cell.styles.valign = 'middle';
            }
          },
        });
      }
      doc.save('ELENCO DOCUMENTI.pdf');
    });
  }

  public showDialogDeleteFile(idFile: number) {
    this.isShowDialogDeleteDoc = true;
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler cancellare questo file?',
      header: 'Cancella File',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      dismissableMask: false,
      accept: async () => {
        await this.deleteFile(idFile);
      },
      reject: (type: ConfirmEventType) => {
        if (type === ConfirmEventType.REJECT) {
          this.isShowDialogDeleteDoc = false;
        }
      },
    });
  }

  /**
   * Ordinamento delle girglia in base alla colonna scelta
   * @param event la colonna da ordinare
   */
  public async customSort(event: SortEvent) {
    if(event.field && event.order !== this.sortOrder) {
      this.sortOrder = event.order;
      const orderColumn = this.ordinamentoPipe.transform(event.field);
      const ordinamento = event.order === 1 ? TipoOrdinamento.Ascendente : TipoOrdinamento.Discendente;
      await this.filterBrowse(this.selectedModelli, orderColumn, ordinamento);
      if (this.isSorted === null || this.isSorted === undefined) {
        this.isSorted = true;
      } else if (this.isSorted === true) {
        this.isSorted = false;
      } else if (this.isSorted === false) {
        this.isSorted = null;
        this.resetTableSort();
      }
    } else {
      this.sortOrder = undefined;
    }
  }
  
  /**
   * Recupero il modello di riferimento
   */
  protected async getModelliIds(selectedModelli: NomeModello[]) {
    let modelliIds: number[] = [];
    const modelliDocumentali = this._sharedServices.getModelliDocumentali();
    const modelliSezione = this.getModelliBySezione(modelliDocumentali);
    if (modelliSezione && modelliSezione.length > 0) {
      selectedModelli.forEach((modello) => {
        const modelloFind = modelliSezione.find(m => m.nome === modello.nomeModello);
        if (modelloFind) {
          modelliIds.push(modelloFind.id);
        }
      });
    }
    return modelliIds;
  }
}
