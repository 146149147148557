import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { ConsoleInterceptorService } from './console-interceptor.service';

@Injectable()
export class HttpLoggingInterceptor implements HttpInterceptor {
  constructor(private consoleService: ConsoleInterceptorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = Date.now();

    // Log the request
    this.consoleService.addHttpLog(`REQUEST: ${req.method} ${req.urlWithParams}`, req.body);

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const elapsedTime = Date.now() - startTime;

          // Log the response
          this.consoleService.addHttpLog(
            `RESPONSE: ${req.method} ${req.urlWithParams} (Status: ${event.status}) - ${elapsedTime}ms`,
            event.body
          );
        }
      })
    );
  }
}
