<!-- Quadro economico attuale-->
<div *ngIf="qe">

  <!-- DIV CHE MOSTRA LE INFO DEL CUP DALLO STORICO -->
  <div *ngIf="isStorico" style="padding-bottom: 1em">
    <div *ngIf="qe.publishedAt">
      <span style="font-weight:500">
        <span style="font-weight:700">{{qe.detail.descrizione}}</span> <br />
        Approvato con: {{qe.detail.atto}} N°{{qe.detail.numero}} del {{qe.detail.data | date:'dd/MM/yyyy'}}
      </span>
    </div>
    <div *ngIf="!qe.publishedAt">
      <span style="font-weight:700">DETTAGLIO BOZZA</span>
    </div>
  </div>

  <div style="display: flex; justify-content: flex-end; margin-bottom: 10px; gap: 10px;">
    <button *ngIf="isDraft && !editCurrent && (!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user?.systemRoles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
      label="SALVA COME MODELLO" pButton style="min-width: 150px; display: block" icon="pi pi-check" (click)="saveModelloDialog()">
    </button>
    <rbk-export-excel *ngIf="cup && qe" [cup]="cup" [qe]="qe"
      [tipoEsportazione]="esportaExcelTipo.QuadroEconomico"></rbk-export-excel>
  </div>

  <p-accordion class="w-full">
    <p-panel header="Dati Generali" [toggleable]="true" id="dGenerali" expandIcon="pi pi-chevron-left"
      collapseIcon="pi pi-chevron-down" [collapsed]="true">

      <div style="display: flex; flex-direction: row; width: 100%;">
        <div style="flex-grow: 1;">
          <p-card class="noBorder">
            <div style="position: relative; min-height: 40vh;">
              <upload-file *ngIf="qe && datiModelloDocumentale?.formGroup" nomeModello="Quadro Economico" [qe]="qe"
                [datiModelloDocumentale]="datiModelloDocumentale" [showSecondButton]="false" [isQeAttuale]="isAttuale"
                [isQeBozza]="isDraft" [abilitaPubblica]="isInEdit" [visualizza]="isDraft" [editCurrent]="editCurrent"
                [entityIdAdd]="entityIdAdd" [isEditQeStorico]="isStorico" [totaleQE]="qeTotal" [cup]="cup" [user]="user" [permesso]="permesso"
                (bloccaSaveBozza)="bloccaBtnSalvaBozza($event)" (add)="pubblicaVariante()"></upload-file>
            </div>
          </p-card>
        </div>
      </div>

    </p-panel>

    <!--Sezioni del Quadro economico-->
    <div style="padding-bottom: 3em" *ngIf="sectionsAreReady">
      <div class="my-2" *ngFor="let section of qe?.sections">
        <p-panel [toggleable]="true" expandIcon="pi pi-chevron-right" collapseIcon="pi pi-chevron-down"
          [collapsed]="false">
          <ng-template pTemplate="header">
            <div class="d-flex flex-row w-full" style="align-items: center">
              <span class="flex-1 d-flex flex-row align-items-center ">
                <p-inplace [preventClick]="!isInEdit" class="p-m-1 flex-1 not-disabled" closeIcon="pi pi-check"
                  [closable]="true">
                  <ng-template pTemplate="display">
                    <span style="font-weight:500">Sezione {{section.title}}

                      <span *ngIf="section.description" style="font-weight:500"> - </span>
                      <span style="font-weight:700">{{section.description}}</span>

                    </span>
                  </ng-template>
                  <ng-template pTemplate="content">
                    <input [readOnly]="!isInEdit" [(ngModel)]="section.description" type="text" placeholder="Titolo"
                      pInputText />
                  </ng-template>
                </p-inplace>
              </span>
              <span style="font-weight: 700">
                {{section.total | currency: 'EUR':'symbol':undefined:'it-IT'}}
              </span>
              <span>
                <span *ngIf="isInEdit" (click)="addHeader(section)"
                  style="border:1px solid #dadada; padding: .5em; border-radius: 6px; margin-left: 1em; font-weight: 700; color: #3B82F6; background-color: #fafafa!important; cursor: pointer">
                  <div class="col-auto pi pi-plus" icon="pi pi-plus"></div>
                </span>
                <span *ngIf="isInEdit && (section.title === 'C')" (click)="delSezioneC()"
                  style="border:1px solid #dadada; padding: .5em; border-radius: 6px; margin-left: 1em; font-weight: 700; color: #3B82F6; background-color: #fafafa!important; cursor: pointer">
                  <div class="col-auto pi pi-times" icon="pi pi-times"></div>
                </span>
              </span>
            </div>
          </ng-template>

          <div *ngFor="let item of section.items">
            <div *ngIf="item.subIndex === 0">

              <div class="row my-1">
                <div class=" px-2 d-flex align-items-center flex-row pb-1">
                  <div class="p-inputgroup" id="header_{{section.title}}_{{item.index}}">
                    <span style="color:#444" class="p-inputgroup-addon qe-section-header">
                      {{section.title}}.{{item.index}}</span>
                    <input pInputText id="header_{{section.title}}_{{item.title}}_titolo" [readOnly]="!isInEdit"
                      [(ngModel)]="item.title">
                    <p-inputNumber [disabled]="item.importDisabled" id="header_{{section.title}}_{{item.title}}_importo"
                      [(ngModel)]="item.total" placeholder="Importo" class="p-right" mode="decimal" currency="EUR"
                      [minFractionDigits]="2" [maxFractionDigits]="2" [ngStyle]="{'max-width': '8em'}"
                      [suffix]="isInEdit ? '' : ' €' " (ngModelChange)="changeImportHeader($event, true, section, item)"
                      locale="de-DE" [readonly]="!isInEdit">
                    </p-inputNumber>

                    <span (click)="deleteItem(section, item)" class="p-inputgroup-addon" *ngIf="isInEdit"
                      [ngClass]="item.deletable ? 'minusButtonNormal':''">
                      <div class="col-auto pi pi-minus" icon="pi pi-minus"></div>
                    </span>
                    <span (click)="addItem(section, item)" class="p-inputgroup-addon" *ngIf="isInEdit"
                      style="color: #3B82F6; background-color: #fafafa!important; cursor: pointer">
                      <div class="col-auto pi pi-plus" icon="pi pi-plus"></div>
                    </span>
                  </div>
                </div>
              </div>

            </div>

            <div *ngIf="item.subIndex !== 0">

              <div class="my-2">
                <div class="row align-items-center pb-1">
                  <span class="col-sm-12 col-md itemList">
                    <div class="p-inputgroup" id="item_{{section.title}}_{{item.index}}_{{item.subIndex}}">
                      <span class="p-inputgroup-addon">{{section.title}}.{{item.index}}.{{item.subIndex}}</span>
                      <input class="w-full" pInputText id="item_{{section.title}}_{{item.index}}_{{item.subIndex}}"
                        [readonly]="!isInEdit" [ngModel]="item.title"
                        (blur)="changeTitle($event, true, section, item)" />
                      <p-inputNumber [ngStyle]="{'min-width': '8em', 'text-align': 'right'}" class="p-right"
                        id="item_{{section.title}}_{{item.index}}_{{item.subIndex}}_importo" [(ngModel)]="item.total"
                        [minFractionDigits]="2" [maxFractionDigits]="2" placeholder="Importo" mode="decimal"
                        [readonly]="!isInEdit" currency="EUR" locale="it-IT" [suffix]="isInEdit ? '' : ' €' "
                        (ngModelChange)="changeImport($event, true, section, item)">
                      </p-inputNumber>
                      <span (click)="deleteItem(section, item)" class="p-inputgroup-addon" *ngIf="isInEdit"
                        style="color: red; background-color: #fafafa!important; cursor: pointer">
                        <div class="col-auto pi pi-minus" icon="pi pi-minus"></div>
                      </span>
                    </div>
                  </span>
                </div>
              </div>

            </div>

          </div>

        </p-panel>
      </div>
    </div>

    <div *ngIf="qe?.sections && !sezioneC && isDraft" style="padding-bottom: 4em">
      <p-button [disabled]="!isInEdit" label="Aggiungi Sezione C" (click)="addSezione('C')"></p-button>
    </div>

  </p-accordion>
</div>

<div *ngIf="qe && datiModelloDocumentale?.formGroup" class="fixed-bottom"
  style="z-index:1; background-color: #fafafa; border-top: 1px solid #ddd;">
  <div [ngClass]="isStorico ? 'p-4' : 'container py-4'">
    <div class="d-flex flex-row-reverse justify-content-between align-items-center">
      <div style="text-align: right;">
        <span style="font-size: 1.1em">Totale Quadro Economico A + B {{ sezioneC ? "+ C " : ""}} = <span
            style="font-size: 1.3em; font-weight: 700;"
            [ngClass]="[visualizza? 'Total':'movedTotal', editCurrent ? 'Total':'movedTotal']">
            {{qeTotal | currency: 'EUR':'symbol':undefined:'it-IT'}}</span></span>
      </div>
      <div *ngIf="(isDraft || isStorico || visualizza) && (!permesso || permesso === TipoPermesso.ReadWrite|| permesso === TipoPermesso.ReadWriteDelete || user?.systemRoles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
        width="80%" class="flex-1" style="display: flex;">
        <button *ngIf="!isInEdit && isDraft" pButton label="MODIFICA BOZZA" icon="pi pi-pencil"
          (click)="modificaBozza()"></button>
        <button *ngIf="isInEdit && isDraft" [disabled]="!qeTotal || !bloccaSaveBozza" label="SALVA BOZZA" pButton
          style="min-width: 150px;display: block" icon="pi pi-check" (click)="saveBozza()"></button>

        <!--Storico-->
        <button *ngIf="!isInEdit && isStorico && !isAnteprima" pButton label="MODIFICA QE" icon="pi pi-pencil"
          (click)="modificaQEStorico()"></button>
        <button *ngIf="isInEdit && isStorico" [disabled]="!qeTotal" label="SALVA QE" pButton
          style="min-width: 150px;display: block" icon="pi pi-check" (click)="confirm3(qe)"></button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!qe && isDraft && firstQE && (!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user?.systemRoles === RuoloPipe.transform(Ruolo.SuperAdministrator))" style="display: flex; gap: 10px">
  <button label="CREA BOZZA VUOTA" pButton style="min-width: 150px;display: block" icon="pi pi-check"
    (click)="newBozza()">
  </button>
  <button *ngIf="(!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user?.systemRoles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
    label="CREA BOZZA DA MODELLO" pButton style="min-width: 150px;display: block" icon="pi pi-check" (click)="newBozzaDaModello()">
  </button>
</div>

<!--Btn Crea Bozza-->
<div *ngIf="!qe && isDraft && !firstQE && (!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user?.systemRoles === RuoloPipe.transform(Ruolo.SuperAdministrator))" style="display: flex; gap: 10px">
  <button label="CREA BOZZA" pButton style="min-width: 150px;display: block" icon="pi pi-check" (click)="newBozza()">
  </button>
</div>

<p-dialog header="Lista modelli" [(visible)]="showListaModelli" [modal]="true" [draggable]="false"
  [style]="{width: '50vw'}">
  <div *ngIf="listaModelli && listaModelli.length === 0">Non sono presenti modelli associati a questo ente.</div>

  <p-table [value]="listaModelli" selectionMode="single">
    <ng-template pTemplate="body" let-model>
      <tr [pSelectableRow]="model">
        <td style="width: 95%;" (click)="confirmModel(model)">{{model.nome}}</td>
        <td style="width: 5%;">
          <div>
            <p-button type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger"
              (click)="confirmDeleteModel(model)"></p-button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<p-dialog header="Salva modello" [(visible)]="saveModel" [modal]="true" [draggable]="false" [style]="{width: '50vw'}">
  <form [formGroup]="modelQE">
    <div class="form-group col-md-12">
      <label for="nome" class="col-form-label">Nome modello</label>
      <input formControlName="nome" id="nome" type="text" class="form-control" placeholder="Nome modello">
      <small style="color: #dc3545;" *ngIf="!modelQE.get('nome').valid && modelQE.get('nome').touched">
        Inserisci un nome per questo modello</small>
    </div>
  </form>

  <div style="display: flex; justify-content: end; margin-top: 2em; gap: 10px;">
    <button pButton label="ANNULLA" class="p-button-secondary" (click)="saveModel = false"></button>
    <button pButton pRipple [disabled]="!modelQE.valid" class="p-button-success"
      style="padding: 0.75rem 1.25rem; height: 2.2em;" label="Salva modello" (click)="saveModello()"></button>
  </div>
</p-dialog>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>