export class Utente {
  id: string;
  qualification: string;
  firstName: string;
  lastName: string;
  userName: string; //username
  createdAt: Date;
  address: string;
  city: string;
  province: string;
  fiscalCode: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  isLocked: boolean;
  roles: string;
  systemRoles?: string;
  clientId: string;
  flagAttivo?: boolean;
  emailConfirmed?: boolean;
  passwordLocked?: boolean;

  constructor(id: string, qualification: string, firstName: string, lastName: string, userName: string, createdAt: Date, address: string,
    city: string, province: string, fiscalCode: string, phoneNumber: string, mobilePhoneNumber: string, isLocked: boolean, roles: string, 
    clientId: string, flagAttivo?: boolean, emailConfirmed?: boolean, passwordLocked?: boolean)
  {
    this.id = id || '';
    this.qualification = qualification || '';
    this.firstName = firstName || '';
    this.lastName = lastName || '';
    this.userName = userName || '';
    this.createdAt = createdAt || new Date;
    this.address = address || '';
    this.city = city || '';
    this.province = province || '';
    this.fiscalCode = fiscalCode || '';
    this.phoneNumber = phoneNumber || '';
    this.mobilePhoneNumber = mobilePhoneNumber || '';
    this.isLocked = isLocked || false;
    this.roles = roles || '';
    this.clientId = clientId || '';
    this.flagAttivo = flagAttivo || true;
    this.emailConfirmed = emailConfirmed || false;
    this.passwordLocked = passwordLocked || false;
  }
}

export class GestioneUtente {
  id: number;
  idUtente: string;
  ruolo: string;
  percorso : Percorso[];
}

export interface Percorso{
  tab: string;
  permesso: string
}

export class Login{
  userName: string;
  password: string;
  ente: string;
}

export interface LoginResponse {
  accessToken: string;
  expiresIn: string;
  tokenType: string;
  scope: string;
  role?: string;
}

export enum Ruolo {
  SuperAdministrator = 1,
  administrator = 2,
  user = 3,
}



// ----------------------------------------------------------
// ----------------AUTORIZZAZIONI/PERMESSI-------------------
// ----------------------------------------------------------
// Definisce il tipo di permessi
export enum TipoPermesso
{
  // Nessun permesso
  None = 0,
  // Solo lettura
  Read = 1,
  // Lettura scrittura modifica
  ReadWrite = 2,
  // Lettura scrittuta modifica cancellazione
  ReadWriteDelete = 3,
}

// Permessi utente
export class UserAuthDto
{
  // UserName dell'account
  public userName: string;
  // Permessi
  public auths: AuthDto[];
}

export interface UserAuthDtoResponse {
  dto: UserAuthDto;
  success: boolean;
}

export class UserRoleAuthDto extends UserAuthDto {
  public ruolo: string;
}

// Definisione dell'autorizzazioni
export class AuthDto
{
  // Identificativo area
  public idArea: number;
  // Identificativo cup
  public idCup: number;
  // Tipo permesso
  public tipoPermesso: TipoPermesso;
}

// Area di riferimento
export class AreaDto {
  public id: number;
  public descrizione: string;
}

export interface AreaDtoResponse {
  dtos: AreaDto[];
  success: boolean;
}

export interface UserRoleAuthDtoResponse {
  success: boolean;
  dto: UserRoleAuthDto;
}

export class SearcherUserDto {
// Identificativo dell'ent
public clientId: string;
// Identificativo del CU
public idCup: number;
// Username
public username: string;
}

export class PermessiArea {
  public permessoCup: TipoPermesso;
  public permessoQE: TipoPermesso;
  public permessoEsecuzione: TipoPermesso;
  public permessoDocumenti: TipoPermesso;
  public permessoFonti: TipoPermesso;
  public permessoPromemoria: TipoPermesso;
  public permessoAnnotazioni: TipoPermesso;
}