<app-nav-menu></app-nav-menu>

<main class="custom-container">
  <router-outlet></router-outlet>
</main>


<ngx-spinner style="z-index: 99999999;" bdcolor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
  [fullScreen]="true"></ngx-spinner>
<div id="anchor" style="z-index: 499999;"></div>
<p-confirmDialog [breakpoints]="{'960px': '85vw', '700px': '95vw'}" [style]="{width: '60vw'}" [baseZIndex]="10000">
</p-confirmDialog>
<p-toast></p-toast>
<p-sidebar [(visible)]="sidebarVisible" position="right"  styleClass="w-50rem">
  <app-upload-manager></app-upload-manager>
</p-sidebar>
<p-toast 
    position="bottom-center" 
    key="confirm" 
    (onClose)="onClose()" 
    [baseZIndex]="5000">
        <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
            <section class="flex p-3 gap-3 w-full bg-black-alpha-90 shadow-2" style="border-radius: 10px">
                <i class="pi pi-cloud-upload text-primary-500 text-2xl"></i>
                <div class="flex flex-column gap-3 w-full">
                    <p class="m-0 font-semibold text-base text-white">
                      Upload in corso...
                    </p>
                    <div class="flex gap-3 mb-3">
                        <p-button
                            label="Apri Upload Manager"     
                            styleClass="p-0" 
                            (onClick)="openUploadManager()" ></p-button>
                        <p-button 
                            label="Nascondi" 
                            styleClass="text-white p-0" 
                            (onClick)="onClose()" ></p-button>  
                    </div>
                </div>
            </section>
        </ng-template>
</p-toast>
