import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  timestamp: Date;
  sha: string
  ngOnInit(): void {
    this.timestamp = this.getDate(environment.timeStamp)
    this.sha = environment.commitSha
  }
  getDate(dateString: string): Date {

    // Extract date, time, and timezone from the input string
    const [datePart, timePart, timezonePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hour, minute, second] = timePart.split(":");
    const timezone = timezonePart.trim();

    // Create a new Date object using the extracted values
    const convertedDate = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);

    // Adjust the timezone offset based on the provided timezone (CEST in this case, which is UTC+2)
    // Note that this assumes the input date string is in CEST timezone and the system's timezone is UTC.
    const timezoneOffsetInMinutes = 120; // 2 hours (CEST offset from UTC)
    const timezoneOffsetInMilliseconds = timezoneOffsetInMinutes * 60 * 1000;
    convertedDate.setTime(convertedDate.getTime() - timezoneOffsetInMilliseconds);

    return convertedDate
  }
}

