import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { throttleTime } from 'rxjs';
import { UploadFile } from 'src/app/model/uploadFile';
import { DocumentiService } from 'src/app/service/documenti.service';
import { UploadService } from 'src/app/service/upload.service';

@Component({
  selector: 'app-upload-manager',
  templateUrl: './upload-manager.component.html',
  styleUrls: ['./upload-manager.component.scss']
})
export class UploadManagerComponent  {

  uploads: UploadFile[] = [];

  constructor(private uploadService: UploadService,private cdr: ChangeDetectorRef, private ngZone: NgZone) {}
  trackByUploadId(index: number, upload: UploadFile): string {
    return upload.uploadId;
  }
  ngOnInit(): void {
    this.uploadService.getUploadStatusChanges()
    .subscribe((uploadFile: UploadFile) => {
      this.ngZone.run(() => {
        const index = this.uploads.findIndex(upload => upload.uploadId === uploadFile.uploadId);

      if (index!==-1) {
        this.uploads[index] = {
          ...this.uploads[index],
          progressAspNet: uploadFile.progressAspNet,
          progressS3: uploadFile.progressS3,
          status: uploadFile.status,
          message: uploadFile.message
        };
      } else {
        console.log(uploadFile)
        this.uploads.push(uploadFile);
      }
    });
    });
  }
  getCombinedProgress(upload: any): number {
    // Calcola il progresso combinato come media
    return (upload.progressAspNet + upload.progressS3) / 2;
  }

  getProgressBarColor(upload: any): string {
    // Restituisce una classe CSS in base al progresso
    return this.getCombinedProgress(upload) >= 50 ? 'progress-high' : 'progress-low';
  }
  getStatusColor(status: string): string {
    switch (status) {
      case 'pending':
        return 'status-pending';
      case 'uploadToAspNet':
        return 'status-upload-to-aspnet';
      case 'uploadToS3':
        return 'status-upload-to-s3';
      case 'successful':
        return 'status-successful';
      case 'failed':
        return 'status-failed';
      default:
        return '';
    }
  }
  statusNames: { [key: string]: string } = {
    pending: 'In attesa',
    uploadToAspNet: 'Convalida Dati',
    uploadToS3: 'Salvataggio in Corso',
    successful: 'Completato',
    failed: 'Errore'
  };

  getStatusName(status: string): string {
    return this.statusNames[status] || status;
  }
  getSeverity(status: string): string {
    switch (status) {
      case 'pending':
        return 'primary';
      case 'uploadToAspNet':
      case 'uploadToS3':
        return 'info';
      case 'successful':
        return 'success';
      case 'failed':
        return 'error';
      default:
        return 'info';
    }
  }
  getMessage(upload: UploadFile): any {
    return {
      severity: 'secondary' , // this.getSeverity(upload.status),
      summary: upload.documento.nomeFile,
      detail: `${this.getStatusName(upload.status)}`,
    };
  }
  closeUpload(upload: UploadFile): void {
    upload.hidden = false;
  }
}
