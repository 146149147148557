import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
registerLocaleData(localeIT);
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component'
import { HomeComponent } from './home/home.component';
import { ScadenzarioComponent } from './components/scadenzario/scadenzario.component';
import { CupListComponent } from './components/cup-list/cup-list.component';
import { CupPromemoriaComponent } from './components/cup-promemoria/cup-promemoria.component';
import { CupAnnotazioniComponent } from './components/cup-annotazioni/cup-annotazioni.component';
import { ConfigurazioneComponent } from './components/configurazione/configurazione.component';
import { ProfessionistiComponent } from './components/professionisti/professionisti.component';
import { ProfessionistaFormComponent } from './components/professionisti/professionista-form/professionista-form.component';
import { CupQEComponent } from './components/cup-qe/cup-qe.component';
import { QEItemComponent } from './components/cup-qe/qe-item/qe-item.component';
import { EnteComponent } from './components/ente/ente-form/ente.component';
import { EnteListComponent } from './components/ente/ente-list.component';
import { UtentiListComponent } from './components/utente/utenti-list.component';
import { FontiFinanziamento } from './components/fonti-finanziamento/fonti-finanziamento.component';
import { LoginComponent } from './components/login/login.component';
import { GestioneUtentiComponent } from './components/gestione-utenti/gestione-utenti.component';
import { GestioneUtentiFormComponent } from './components/gestione-utenti/gestione-utenti-form/gestione-utenti-form.component';
import { UtenteComponent } from './components/utente/utente-form/utente.component';
import { InvitaFormComponent } from './components/utente/invita-form/invita-form.component';
import { RaffrontoComponent } from './components/cup-qe/raffronto/raffronto.component';
import { CupPromemoriaFormComponent } from './components/cup-promemoria/cup-promemoria-form/cup-promemoria-form.component';
import { CupEsecuzioneComponent } from './components/cup-esecuzione/cup-esecuzione.component';

// Import PrimeNG modules 
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SidebarModule } from 'primeng/sidebar';

// import { AvatarModule } from 'primeng/avatar';
// import { BadgeModule } from 'primeng/badge';
// import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
// import { CarouselModule } from 'primeng/carousel';
// import { CascadeSelectModule } from 'primeng/cascadeselect';
// import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
// import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
//import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
// import { ColorPickerModule } from 'primeng/colorpicker';
// import { ContextMenuModule } from 'primeng/contextmenu';
// import { DataViewModule } from 'primeng/dataview';
// import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DockModule } from 'primeng/dock';
// import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
// import { EditorModule } from 'primeng/editor';
// import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
// import { GalleriaModule } from 'primeng/galleria';
// import { GMapModule } from 'primeng/gmap';
import { InplaceModule } from 'primeng/inplace';
// import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextareaModule } from 'primeng/inputtextarea';
// import { ImageModule } from 'primeng/image';
// import { KnobModule } from 'primeng/knob';
// import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
// import { MegaMenuModule } from 'primeng/megamenu';
// import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
// import { OrderListModule } from 'primeng/orderlist';
// import { OrganizationChartModule } from 'primeng/organizationchart';
// import { OverlayPanelModule } from 'primeng/overlaypanel';
// import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
// import { PanelMenuModule } from 'primeng/panelmenu';
// import { PasswordModule } from 'primeng/password';
// import { PickListModule } from 'primeng/picklist';
// import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
// import { RatingModule } from 'primeng/rating';
// import { ScrollerModule } from 'primeng/scroller';
// import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
// import { SelectButtonModule } from 'primeng/selectbutton';
// import { SidebarModule } from 'primeng/sidebar';
// import { SkeletonModule } from 'primeng/skeleton';
// import { SlideMenuModule } from 'primeng/slidemenu';
// import { SliderModule } from 'primeng/slider';
import { SpeedDialModule } from 'primeng/speeddial';
// import { SpinnerModule } from 'primeng/spinner';
// import { SplitButtonModule } from 'primeng/splitbutton';
// import { SplitterModule } from 'primeng/splitter';
// import { StepsModule } from 'primeng/steps';
// import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
 import { TagModule } from 'primeng/tag';
// import { TerminalModule } from 'primeng/terminal';
// import { TieredMenuModule } from 'primeng/tieredmenu';
// import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
// import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
// import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
// import { TreeModule } from 'primeng/tree';
// import { TreeSelectModule } from 'primeng/treeselect';
// import { TreeTableModule } from 'primeng/treetable';
// import { AnimateModule } from 'primeng/animate';
import { CardModule } from 'primeng/card';
// import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CupMainComponent } from "./components/cup-main/cup-main.component";
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ExportExcelComponent } from './components/rbk-export-excel/rbk-export-excel';
import { RbkDocumentiComponent } from './components/rbk-documenti/rbk-documenti';
import { TipoCampoPipe, TipoChiavePipe, TipoEntitaPipe } from './pipe/tipo-entita-pipe';
import { RbkUploadFileComponent } from './components/rbk-upload-file/rbk-upload-file';
import { ExtensionTypePipe, FileNamePipe, FormatFileSizePipe } from './pipe/format-size-pipe';
import { AuthorizeInterceptor } from './service/authorize.interceptor';
import { AuthorizeGuard } from './service/authorize.guard';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ConfirmResetPasswordComponent } from './components/confirm-reset-password/confirm-reset-password.component';
import { ErrorCatchingInterceptor } from 'src/error-catching-interceptor';
import { UploadFileComponent } from './components/shared/upload-file/upload-file';
import { HttpTimingInterceptor } from './http-timing.interceptor';
import { DocumentiSospesiComponent } from './components/documenti-sospesi/documenti-sospesi';
import { RbkModalComponent } from './components/shared/rbk-modal/rbk-modal';
import { DateMaskDirective } from './directive/date-mask.directive';
import { ModelliDocumentaliComponent } from './components/modelli-documentali/modelli-documentali';
import { RuoloPipe } from './pipe/ruolo-pipe';
import { AreaPipe, PermessoPipe, TipoPermessoPipe } from './pipe/permessi-pipe';
import { KeyTypePipe } from './pipe/key-type-pipe';
import { FormatBytesPipe } from './pipe/formatBytes.pipe';
import { UploadManagerComponent } from './components/shared/upload-manager/upload-manager.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { HttpLoggingInterceptor } from './http-interceptor.service';
export function tokenGetter() {
  return localStorage.getItem("jwt");
}


@NgModule({
  declarations: [
    DateMaskDirective,
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CupListComponent,
    ScadenzarioComponent,
    ExportExcelComponent,
    RbkDocumentiComponent,
    RbkUploadFileComponent,
    CupMainComponent,
    CupPromemoriaComponent,
    CupQEComponent,
    QEItemComponent,
    CupPromemoriaFormComponent,
    CupEsecuzioneComponent,
    CupAnnotazioniComponent,
    RaffrontoComponent,
    FontiFinanziamento,
    ProfessionistiComponent,
    ProfessionistaFormComponent,
    LoginComponent,
    EnteComponent,
    UtenteComponent,
    GestioneUtentiComponent,
    GestioneUtentiFormComponent,
    ResetPasswordComponent,
    ConfirmResetPasswordComponent,
    ChangePasswordComponent,
    ConfigurazioneComponent,
    TipoEntitaPipe,
    EnteListComponent,
    UtentiListComponent,
    InvitaFormComponent,
    UploadFileComponent,
    UploadManagerComponent,
    FormatFileSizePipe,
    ExtensionTypePipe,
    FileNamePipe,
    DocumentiSospesiComponent,
    RbkModalComponent,
    ModelliDocumentaliComponent,
    TipoChiavePipe,
    TipoCampoPipe,
    RuoloPipe,
    FormatBytesPipe,
    TipoPermessoPipe,
    PermessoPipe,
    AreaPipe,
    KeyTypePipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    MenubarModule,
    ReactiveFormsModule,
    TableModule,
    TabViewModule,
    ButtonModule,
    NgxSpinnerModule,
    PanelModule,
    DockModule,
    InplaceModule,
    TagModule,
    DropdownModule,
    AccordionModule,
    InputTextModule,
    DropdownModule,
    BrowserAnimationsModule,
    CardModule,
    TabViewModule,
    ToastModule,
    SidebarModule,
    ProgressBarModule,
    ScrollTopModule,
    DialogModule,
    CalendarModule,
    RadioButtonModule,
    CheckboxModule,
    TooltipModule,
    ChipsModule,
    FileUploadModule,
    InputTextareaModule,
    ProgressSpinnerModule ,
    ConfirmDialogModule,
    SharedModule,
    MessagesModule,
    ListboxModule,
    MultiSelectModule,
    AutoCompleteModule,
    PdfViewerModule,
    InputSwitchModule,
    SpeedDialModule,
    ToggleButtonModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthorizeGuard] }, 
      { path: 'cup', component: CupMainComponent, canActivate: [AuthorizeGuard] },
      { path: 'cup/:id', component: CupMainComponent, canActivate: [AuthorizeGuard] },
      { path: 'login', component: LoginComponent },
      { path: 'changePassword', component: ChangePasswordComponent },
      { path: 'ente', component: EnteListComponent, canActivate: [AuthorizeGuard] },
      { path: 'utente', component: UtentiListComponent, canActivate: [AuthorizeGuard] },
      { path: 'configurazione', component: ConfigurazioneComponent, canActivate: [AuthorizeGuard] },
      { path: 'formUtente', component: UtenteComponent, canActivate: [AuthorizeGuard] },
      { path: 'enteUtenti', component: EnteComponent, canActivate: [AuthorizeGuard] },
      { path: 'DocumentiSospesi', component: DocumentiSospesiComponent, canActivate: [AuthorizeGuard] },
      { path: 'modelliDocumetali', component: ModelliDocumentaliComponent, canActivate: [AuthorizeGuard] },
      {
        path: 'cambiaPassword', children: [
          { path: '', component: ChangePasswordComponent }
        ]
      },
      {
        path: 'resetPassword', children: [
          { path: '', component: ResetPasswordComponent }
        ]
      },
      {
        path: 'confermaResetPassword', children: [
          { path: '', component: ConfirmResetPasswordComponent }
        ]
      }
    ]),
    InputNumberModule,
    CalendarModule,
    DividerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      }
    }),
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    PdfViewerComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoggingInterceptor,
      multi: true,
    },
    /*
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CacheInterceptor,
          multi: true,
        },
    */
    { provide: LOCALE_ID, useValue: 'it-IT' },
    JwtHelperService,
    { provide: MessageService },

    { provide: DialogService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true
    },
    { provide: ConfirmationService }

  ],
  bootstrap: [AppComponent],
  exports: [
    NgxSpinnerModule,
    TipoEntitaPipe,
    FormatFileSizePipe,
    ExtensionTypePipe,
    RbkModalComponent,
    RuoloPipe,

    FormatBytesPipe,
    TipoPermessoPipe,
    PermessoPipe,
    AreaPipe,
    TipoCampoPipe,
    KeyTypePipe,
    ButtonModule
  ],

})
export class AppModule { }
