<form [formGroup]="enteForm">
  <div class="row">

    <div class="form-group col-md-4">
      <label for="ente" class="col-form-label">Ente</label>
      <input  [ngClass]="{'readonly': enteDto} " formControlName="ente" type="text" class="form-control" placeholder="Ente">
      <small style="color: #dc3545;" *ngIf="!enteForm.get('ente').valid && enteForm.get('ente').touched">
        Inserisci un codice valido</small>
    </div>

    <div class="form-group col-md-4">
      <label for="address" class="col-form-label">Indirizzo</label>
      <input formControlName="address" type="text" class="form-control" placeholder="Indirizzo">
      <small style="color: #dc3545;" *ngIf="!enteForm.get('address').valid && enteForm.get('address').touched">
        Inserisci un codice valido</small>
    </div>

    <div class="form-group col-md-4">
      <label for="selectedCity" class="col-form-label">Città</label>
      <p-autoComplete formControlName="selectedCity" [suggestions]="filteredComuni" [showClear]="true"
        (completeMethod)="filterComune($event)" field="denominazioneInItaliano" placeholder="Città"></p-autoComplete>
      <small style="color: #dc3545;" *ngIf="!enteForm.get('selectedCity').valid && enteForm.get('selectedCity').touched">
        Inserisci un codice valido</small>
    </div>


    <div class="form-group col-md-4">
      <label for="provincia" class="col-form-label">Provincia</label>
      <input formControlName="provincia" type="text" class="form-control" placeholder="Provincia">
      <small style="color: #dc3545;" *ngIf="!enteForm.get('provincia').valid && enteForm.get('provincia').touched">
        Inserisci un codice valido</small>
    </div>

    <div class="form-group col-md-4">
      <label for="regione" class="col-form-label">Regione</label>
      <input formControlName="regione" type="text" class="form-control" placeholder="Regione">
      <small style="color: #dc3545;" *ngIf="!enteForm.get('regione').valid && enteForm.get('regione').touched">
        Inserisci un codice valido</small>
    </div>

    <div class="form-group col-md-4">
      <label for="codice" class="col-form-label">Codice</label>
      <input  [ngClass]="{'readonly': enteDto} " formControlName="codice" type="text" class="form-control" placeholder="Codice">
      <small style="color: #dc3545;" *ngIf="!enteForm.get('codice').valid && enteForm.get('codice').touched">
        Inserisci un codice valido</small>
      <!-- <small style="color: red" *n gIf="!fase1Form.get('numero').valid && fase1Form.get('numero').touched">
          Inserisci il numero</small>      [readOnly]="enteDto.id!!" -->
    </div>
  </div>
  <p-divider></p-divider>

  <div class="row">
    <h2 style="font-weight: 700; padding-left: 8px">DATI LICENZA</h2>
    <div class="form-group col-md-4">
      <label for="codiceLicenza" class="col-form-label">Codice licenza</label>
      <input readonly formControlName="codiceLicenza" type="text" class="form-control" placeholder="codiceLicenza">
      <small style="color: #dc3545;"
        *ngIf="!enteForm.get('codiceLicenza').valid && enteForm.get('codiceLicenza').touched">
        Inserisci un codice valido</small>
    </div>
    <div class="form-group col-md-2">
      <label for="dataAttivazione" class="col-form-label">Data attivazione</label>
      <p-calendar formControlName="dataAttivazione" appendTo="body" dateFormat="dd/mm/yy" [readonlyInput]="enteDto != null" ></p-calendar>
      <small style="color: #dc3545;"
        *ngIf="!enteForm.get('dataAttivazione').valid && enteForm.get('dataAttivazione').touched">
        Inserisci una data</small>
    </div>

    <div class="form-group col-md-2">
      <label for="dataProssimaScadenza" class="col-form-label">Data prossima scadenza</label>
      <p-calendar   formControlName="dataProssimaScadenza" appendTo="body" class="moved-picker"
        dateFormat="dd/mm/yy"  [readonlyInput]="enteDto != null" ></p-calendar>
      <small style="color: #dc3545;"
        *ngIf="!enteForm.get('dataProssimaScadenza').valid && enteForm.get('dataProssimaScadenza').touched">
        Inserisci una data</small>
    </div>
    <div class="form-group col-md-2">
      <label for="licenseType" class="col-form-label">Tipo Licenza</label>
      <p-dropdown [options]="licenseTypes"
        formControlName="licenseType" optionValue="id" optionLabel="name"
        placeholder="Tipo Licenza" [ngClass]="{'disabled': enteDto}" 
        appendTo="body">
    </p-dropdown>
      <small style="color: #dc3545;"
        *ngIf="!enteForm.get('licenseType').valid && enteForm.get('licenseType').touched">
        Seleziona un tipo di licenza</small>
    </div>

    <div class="row col-md-2" >
      
    <button  class="col-12" pButton type="button" label="Rinnova"  *ngIf="enteDto && isSuperAdmin" 
    (click)="openRenewDialog()"></button>
    <button  class="col-12" *ngIf="enteDto && enteDto.licenses" pButton 
    label="Storico" (click)="showDialogLicense()"></button>
    </div>
  </div>

  <p-divider></p-divider>

  <div class="row" *ngIf="false">
    <h2 style="font-weight: 700; padding-left: 8px">DATI ARCHIVIAZIONE</h2>
    <div class="form-group col-md-4">
      <label for="spazioAcquistatoS3" class="col-form-label">Spazio Acquistato</label>
      <input formControlName="spazioAcquistatoS3" type="text" class="form-control" placeholder="spazioAcquistatoS3">
      <small style="color: #dc3545;"
        *ngIf="!enteForm.get('spazioAcquistatoS3').valid && enteForm.get('spazioAcquistatoS3').touched">
        Inserisci un codice valido</small>
    </div>
    <div class="form-group col-md-3">
      <label for="spazioUtilizzatoS3" class="col-form-label">Spazio Utilizzato: </label>
      <div class="padding-div-spazi"> {{enteForm.get('spazioUtilizzatoS3').value || ''}}</div>
    </div>

    <div class="form-group col-md-3">
      <label for="spazioDisponibileS3" class="col-form-label">Spazio Disponibile: </label>
      <div class="padding-div-spazi"> {{enteForm.get('spazioDisponibileS3').value || ''}}</div>
    </div>

    <div class="form-group col-md-2">
      <label for="maxDimFileDaCaricare" class="col-form-label">Spazio Acquistato</label>
      <input formControlName="maxDimFileDaCaricare" type="text" class="form-control" placeholder="maxDimFileDaCaricare">
    </div>
  </div>

  <p-divider></p-divider>

  <div class="row">
    <h2 style="font-weight: 700; padding-left: 8px">PARAMETRI ARCHIVIO S3</h2>
    <div class="form-group position-relative"  *ngIf="isSuperAdmin"
      [ngClass]="{'col-md-3':   user.roles.includes('SuperAdministrator'), 'col-md-4': !user.roles.includes('SuperAdministrator')}">
      <label for="s3Url" class="col-form-label">URL</label>
      <input formControlName="s3Url" [type]="hides3Url ? 'password' : 'text'" class="form-control" placeholder="URL">
      <span *ngIf="isSuperAdmin" class="password-toggle-icon" (click)="gestioneIconEye('s3Url')"><i class="pi"
          [ngClass]="{'pi-eye': hides3Url, 'pi-eye-slash': !hides3Url }"></i></span>
      <small style="color: #dc3545;" *ngIf="!enteForm.get('s3Url').valid && enteForm.get('s3Url').touched">
        Inserisci un codice valido</small>
    </div>
    <div class="form-group position-relative"  *ngIf="isSuperAdmin"
      [ngClass]="{'col-md-3': user.roles.includes('SuperAdministrator'), 'col-md-4': !user.roles.includes('SuperAdministrator')}">
      <label for="s3AccessKey" class="col-form-label">Access Key</label>
      <input formControlName="s3AccessKey" [type]="hides3AccessKey ? 'password' : 'text'" class="form-control"
        placeholder="Access Key">
      <span *ngIf="isSuperAdmin" class="password-toggle-icon" (click)="gestioneIconEye('s3AccessKey')"><i class="pi"
          [ngClass]="{'pi-eye': hides3AccessKey, 'pi-eye-slash': !hides3AccessKey }"></i></span>
      <small style="color: #dc3545;" *ngIf="!enteForm.get('s3AccessKey').valid && enteForm.get('s3AccessKey').touched">
        Inserisci un codice valido</small>
    </div>

       <div class="form-group position-relative"  *ngIf="isSuperAdmin"
      [ngClass]="{'col-md-3': user.roles.includes('SuperAdministrator'), 'col-md-4': !user.roles.includes('SuperAdministrator')}">
      <label for="s3SecretKey" class="col-form-label">Secret Key</label>
      <input formControlName="s3SecretKey" [type]="hides3SecretKey ? 'password' : 'text'" class="form-control"
        placeholder="Secret Key">
      <span *ngIf="isSuperAdmin" class="password-toggle-icon" (click)="gestioneIconEye('s3SecretKey')">
        <i class="pi" [ngClass]="{'pi-eye': hides3SecretKey, 'pi-eye-slash': !hides3SecretKey }"></i></span>
      <small style="color: #dc3545;" *ngIf="!enteForm.get('s3SecretKey').valid && enteForm.get('s3SecretKey').touched">
        Inserisci un codice valido</small>
    </div>
    <div *ngIf="enteDto && enteDto.storage" class="form-group position-relative col-12" style="margin-top: 1em; margin-bottom: 2em;"  >
    <div class="progress" role="progressbar" [attr.aria-valuenow]="(enteDto.storage - enteDto.availableStorage) / enteDto.availableStorage * 100" aria-valuemin="0" aria-valuemax="100" style="height: 30px">
      <div class="progress-bar overflow-visible bg-warning" [style.width.%]="(enteDto.storage - enteDto.availableStorage) / enteDto.storage * 100">
        {{ ((enteDto.storage - enteDto.availableStorage) / enteDto.storage * 100) | number:'1.0-2' }}%
      </div>
    </div>

    <span><b>{{ enteDto.storage - enteDto.availableStorage | formatBytes }}</b> di <b>{{ enteDto.storage | formatBytes }}</b> utilizzati</span>
  
  </div>
    <div  *ngIf="isSuperAdmin" class="form-group col-md-3 position-relative">
      <label for="s3SecretKey" class="col-form-label" style="color:transparent;">Secret Key</label>
      <button pButton label="Verifica" (click)="verificaParametris3()"
        [disabled]="!enteForm.get('s3SecretKey').valid || !enteForm.get('s3AccessKey').valid || !enteForm.get('s3Url').valid "></button>
    </div>
  </div>

  <div *ngIf="!isAdmin" class="pt-4">
    <div class="d-flex justify-content-end">
      <button pButton label="ANNULLA" class="p-button-secondary" (click)="close(false)"></button>
      <button *ngIf="!isInEdit" pButton label="AGGIUNGI" icon="pi pi-plus" [disabled]="enteForm.invalid"
        (click)="confirm()"></button>
      <button *ngIf="isInEdit && !enteForm.enabled" pButton label="MODIFICA" [disabled]="enteForm.invalid"
        icon="pi pi-pencil" (click)="enable()"></button>
      <button *ngIf="isInEdit && enteForm.enabled" pButton pRipple label="SALVA" [disabled]="enteForm.invalid"
        icon="pi pi-check" (click)="confirmSave()"></button>
    </div>
  </div>

  <p-divider></p-divider>

  <div class="d-flex justify-content-between">
    <span style="font-weight: 700; font-size: 16px;">Gestione Utenti</span>
    <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined" [disabled]="enteForm.disabled"
      (onClick)="addUtente()"></p-button>
  </div>

  <div class="row my-3">
    <div class="col-sm-6">
      <strong>Username</strong>
    </div>
    <div class="col-sm-2">
      <strong>Ruolo</strong>
    </div>
    <div class="col-sm-2">
      <strong>Invita</strong>
    </div>
    <div class="col-sm-2">
      <strong>Elimina</strong>
    </div>
    <!-- <div class="col-sm-2">
      <strong>Bloccato</strong>
    </div> -->
  </div>

  <div formArrayName="users">
    <div *ngFor="let utenteGroup of enteFormArray.controls; let i = index" [formGroupName]="i" class="row my-2">

      <div class="form-group col-sm-6 my-3">
        <input formControlName="userName" type="text" class="form-control" placeholder="Email"
          [disabled]="utenteGroup?.value?.userName">
      </div>

      <div class="col-md-2  my-3">
        <div class="d-block">
          <div class="custom-control custom-radio">
            <input type="radio" formControlName="roles" value="administrator" class="custom-control-input">
            <label class="custom-control-label" for="administrator">Amministratore</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" formControlName="roles" value="user" class="custom-control-input">
            <label class="custom-control-label" for="utente">Utente</label>
          </div>
        </div>
      </div>

      <div class="form-group col-md-2  my-3">
        <button pButton [label]="enteFormArray.controls[i].get('labelButton').value" styleClass="p-button p-button-text invita-button" icon="pi pi-send"
          [ngClass]="{ 'is-invita': enteFormArray.controls[i].get('labelButton').value === 'Invita' }"
          [disabled]="!utenteGroup.enabled || enteForm.invalid || enteFormArray.controls[i].get('roles').disabled" (click)="associate(utenteGroup, true)"></button>
      </div>

      <div class="form-group col-md-2  my-3">
        <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text"
          [disabled]="!utenteGroup.enabled || enteFormArray.controls[i].get('roles').disabled" (onClick)="confirmDeleteFromLista(i, utenteGroup)" for="delete"></p-button>
      </div>
      <!-- <div class="form-group col-md-2 my-3">
        <p-button icon="{{utenteGroup?.value?.lock ? 'pi pi-lock' : 'pi pi-lock-open'}}" styleClass="p-button p-button-text"
          (onClick)="onChangeLock(utenteGroup?.value?.lock, utenteGroup)" [disabled]="!utenteGroup.enabled"></p-button>
      </div> -->

    </div>
  </div>
</form>

<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
<p-toast></p-toast>

<rbk-modal *ngIf="visibleDialog" [dialogModel]="dialogModel" (onConfirm)="visibleDialog = false"></rbk-modal>
<p-dialog [(visible)]="licensesDialog" header="Lista Licenze" [modal]="true" [style]="{ width: '50vw' }" [closable]="true">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Data Inizio</th>
        <th>Data Fine</th>
        <th>Tipo Licenza</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let license of sortedLicenses">
        <td>{{ license.startLicense | date: 'dd/MM/yyyy' }}</td>
        <td>{{ license.endLicense | date: 'dd/MM/yyyy' }}</td>
        <td>{{ licenseTypeFromId(license.licenseType) }}</td>
      </tr>
    </tbody>
  </table>
</p-dialog>
<p-dialog [(visible)]="displayRenewDialog" header="Rinnovo Licenza" [modal]="true" [style]="{ width: '40vw' }" [closable]="true">
  <form [formGroup]="renewLicenseForm" (ngSubmit)="renewLicense()">
    <div class="form-group col-md-12">
      <label for="dataAttivazione" class="col-form-label">Data Attivazione</label>
      <p-calendar formControlName="dataAttivazione" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
      <small style="color: #dc3545;"
        *ngIf="!renewLicenseForm.get('dataAttivazione').valid && renewLicenseForm.get('dataAttivazione').touched">
        Inserisci una data valida</small>
    </div>

    <div class="form-group col-md-12">
      <label for="dataProssimaScadenza" class="col-form-label">Data Prossima Scadenza</label>
      <p-calendar formControlName="dataProssimaScadenza" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
      <small style="color: #dc3545;"
        *ngIf="!renewLicenseForm.get('dataProssimaScadenza').valid && renewLicenseForm.get('dataProssimaScadenza').touched">
        Inserisci una data valida</small>
    </div>

    <div class="form-group col-md-12">
      <label for="licenseType" class="col-form-label">Tipo Licenza</label>
      <p-dropdown [options]="licenseTypes" formControlName="licenseType" optionValue="id" optionLabel="name"
        placeholder="Seleziona Tipo Licenza" appendTo="body">
      </p-dropdown>
      <small style="color: #dc3545;"
        *ngIf="!renewLicenseForm.get('licenseType').valid && renewLicenseForm.get('licenseType').touched">
        Seleziona un tipo di licenza</small>
    </div>

    <!-- Pulsanti del dialog -->
    <div class="text-right">
      <button pButton type="button" label="Annulla" icon="pi pi-times" class="p-button-text" (click)="closeRenewDialog()"></button>
      <button pButton type="submit" label="Salva" icon="pi pi-check" class="p-button-primary" [disabled]="!renewLicenseForm.valid"></button>
    </div>
  </form>
</p-dialog>