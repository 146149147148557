<div class="row contenitore">
  <div class="header">
    <h1 class="title-card">LISTA ENTI</h1>
    <button pButton (click)="showDialog()" label="NUOVO ENTE" icon="pi pi-plus" style="height: 2.25em;"></button>
  </div>

  <p-dialog *ngIf="visible" header="Nuovo Ente" [(visible)]="visible" [style]="{width: '75vw'}" [modal]="true"
    [draggable]="false">
    <app-ente [enteDto]="selectedEnte" [isInEdit]="isInEdit" [visible]="visible"
      (closeDialog)="onClose($event)"></app-ente>
  </p-dialog>


  <p-card>
    <p-table [value]="filteredTab" [paginator]="true" [rows]="15" [showCurrentPageReport]="true"
      currentPageReportTemplate="Da {first} a {last} di {totalRecords} Enti" [rowsPerPageOptions]="[15, 25, 50]"
      [(selection)]="selectedEnte" dataKey="codiceCliente">

      <ng-template pTemplate="header">
        <tr style="border: 1px solid #dee2e6;">
          <th style="width: 6%">Cliente Attivo</th>
          <th style="width: 6%">Spazio</th>
          <th style="width: 29%">Cliente</th>
          <!-- <th>Indirizzo</th> -->
          <th style="width: 18%">Città</th>
          <th style="width: 6%">Provincia</th>
          <th style="width: 8%">Codice Licenza</th>
          <th style="width: 7%">Codice di Accesso</th>
          <th style="width: 10%">Prima Attivazione</th>
          <th style="width: 10%">Prossima Scadenza</th>
          <th style="width: 7%"></th>
          <!-- <th>Utenti Admin</th>
          <th style="width: 4%">Utente Attivo</th>
          <th style="width: 4%">Reset</th>
          <th style="width: 4%">Fascicolo Documenti Contratto</th> -->
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-ente>
        <tr [pSelectableRow]="ente" style="border: 1px solid #dee2e6;" (dblclick)="showDialog(ente)">
          <td>{{ ente.flagAttivo }}</td>
          <td>{{ ente.storage | formatBytes }}</td>
          <!-- <td>{{ ente.condiceCliente }}</td> -->
          <td>{{ ente.ente }}</td>
          <!-- <td>{{ ente.indirizzo }}</td> -->
          <td>{{ ente.city }}</td>
          <td>{{ ente.provincia }}</td>
          <td>{{ ente.codiceLicenza }}</td>
          <td>{{ ente.codice }}</td>
          <td *ngIf="ente.dataAttivazione">{{ ente.dataAttivazione | date:"dd/MM/yy"}}</td>
          <td *ngIf="!ente.dataAttivazione">-</td>
          <td *ngIf="ente.dataProssimaScadenza">{{ ente.dataProssimaScadenza | date:"dd/MM/yy"}}</td>
          <td *ngIf="!ente.dataProssimaScadenza">-</td>
          <td style="display: flex; flex-direction: row; gap: 10px;">
            <p-button (onClick)="showDialog(ente)" type="button" icon="pi pi-eye" styleClass="p-button-text"></p-button>
            <p-button (onClick)="confirm(ente.id)" type="button" icon="pi pi-trash"
              styleClass="p-button-text p-button-danger"></p-button>
          </td>
          <!-- <td>{{ utente.idUtente }}</td>
          <td>{{ utente.flagAttivo }}</td>
          <td>{{ reset }}</td>
          <td>{{ ente.fascicoloContratto }}</td> -->

          <!-- <td [pTooltip]="ente.titolo">{{ (ente.titolo.length>6)? (ente.titolo | slice:0:20)+'...':(ente.titolo) }}</td>
          <td [pTooltip]="ente.descrizione">{{ (ente.descrizione.length>6)? (ente.descrizione | slice:0:40)+'...':(ente.descrizione) }}</td> -->
        </tr>
      </ng-template>
    </p-table>
  </p-card>

</div>

<!-- [routerLink]="['cup/', cup.id]" -->