export const environment = {
  name: 'prod',
  production: true,
  endpoint: 'https://localhost:7239/',
  timeStamp: '02/12/2024 02:49:54 AM CET',
  commitSha: '51fde7a',
  googleAnalyticsId: 'UA-000000000-0'
};


