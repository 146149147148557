import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { QE, QEItemBase } from '../model/qe';
import { Scadenza } from '../model/scadenza';
import { CUP } from '../model/cup';
import { IndiceTab, ScenarioService } from './scenario.service';
import { DocumentiDaArchiviareBrowse } from '../components/documenti-sospesi/documenti-sospesi';
import { ModelloDocumentale, SezioniDocument } from '../model/documento';
import { Dizionario, TipologiaDizionario } from '../model/scenari';
import { DocumentiService } from './documenti.service';
import { MessageService } from 'primeng/api';

interface Message {
  severity: string,
  summary: string,
  detail: string
}

export class NavigazioneDaDocumenti{
  sezione?: string;
  sottoSezione?: string;
  idSottoSezione?: number;
  documentoID?: number;
  documento?: DocumentiDaArchiviareBrowse;
}

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  
  private uploadManagerVisible = new BehaviorSubject<boolean>(false);
  uploadManagerVisible$ = this.uploadManagerVisible.asObservable();

  setUplaodManagerVisible(value: boolean) {
    this.uploadManagerVisible.next(value);
  }
  private qeSource = new Subject<QE>();
  qe$ = this.qeSource.asObservable();

  private index: number;
  private idCup: number;
  private message: Message;
  private rowsImpegnate: QEItemBase[];

  private cupListBrowse: CUP[];

  private cupList: CUP[] = [];

  private scadenza: Scadenza;

  private scadenze: Scadenza[];

  private datiNavigazione: NavigazioneDaDocumenti;

  private modelliDocumentali: ModelloDocumentale[];
  private modelliDocumentaliFonti: ModelloDocumentale[];

  private anteprimaDocumentoCartellaMonitorata: boolean = false;
  private documentoIdCartellaMonitorata: number;

  private sezioni: SezioniDocument[] = [];
  private allDizionari: Dizionario[] = [];

  constructor (
    private scenarioServices: ScenarioService, 
    private _documentiService: DocumentiService,
    private messageService: MessageService
  ) { }

  /**
   * Restituisce tutti i dizionari 
   */
  async getAllDizionari(isRefreshDizionari: boolean = false, campo?: TipologiaDizionario){
    if(isRefreshDizionari) {
      let resp = await this.scenarioServices.getAllDescrizioniDizionario(campo).toPromise();
      this.allDizionari = resp && resp.success && resp.dtos || [];
    }
    return this.allDizionari;
  }

  async getSezioniModelloDocumentale(){
    if(!this.sezioni || this.sezioni.length === 0){
      const response = await this._documentiService.getSezioniModelliDocumentali().toPromise();
      if(response && response.success){
        this.sezioni  = response.dtos;
      }
    }
    return this.sezioni;
  }

  /**
   * Restituisce i dizionari solo per una specifica categoria
   */
  async getDizionarioByCategoria(categoria: TipologiaDizionario){
    this.allDizionari = await this.getAllDizionari();
    return this.allDizionari.filter(x => x.categoria === categoria);
  }

  /**
   * Nella modifica della liswta dei dizionari, 
   * bisogna cancellare tutto per poterli ricaricare, in modo da avere sempre i valori aggiornati
   * @returns 
   */
  async deleteDizionari(){
    this.allDizionari = [];
    return await this.getAllDizionari(true);
  }

  sendQE(qe: QE) {
    this.qeSource.next(qe);
  }

  setModelliDocumentali(event: ModelloDocumentale[]){
    this.modelliDocumentali = event;
  }
  
  getModelliDocumentali(){
    return this.modelliDocumentali;
  }

  setParametriNavigaDaDocumentiArchviazione(event: NavigazioneDaDocumenti){
    this.datiNavigazione = event;
  }
  
  getParametriNavigaDaDocumentiArchviazione(){
    return this.datiNavigazione;
  }

  isAnteprimaDocumentoCartellaMonitorata(){
    return this.anteprimaDocumentoCartellaMonitorata;
  }

  getDocumentoIdCartellaMonitorata(){
    return this.documentoIdCartellaMonitorata;
  }

  setAnteprimaDocumentoCartellaMonitorata(value: boolean, idDocumento: number){
     this.anteprimaDocumentoCartellaMonitorata = value;
     this.documentoIdCartellaMonitorata = idDocumento;
  }

  deleteParametriNavigaDaDocumentiArchviazione(){
    delete this.datiNavigazione;
  }

  getListaCupBrowse() {
    return this.cupListBrowse;
  }

  setListaCup(cup: CUP) {
    if (cup && cup.id && this.cupList && !this.cupList.find(cup => cup.id === cup.id)) {
      this.cupList.push(cup);
    }
  }

  getCupFromListById(idCup: number) {
    return this.cupList.find(cup => cup.id === idCup);
  }

  setCupId(id: number) {
    this.idCup = id
  }

  changeActiveIndex(n: number, scadenza?) {
    this.index = n;
    if (scadenza) {
      this.scadenza = scadenza;
    }
  }

  getIdCup() {
    return this.idCup;
  }

  getIndex() {
    return this.index;
  }

  setIndex(indice: IndiceTab) {
    this.index = indice;
  }

  setScadenza(scadenza: Scadenza) {
    this.scadenza = scadenza;
  }

  getScadenza() {
    return this.scadenza;
  }

  visualizzaScadenze(scadenze: Scadenza[]) {
    this.scadenze = scadenze;
  }

  getScadenze() {
    return this.scadenze;
  }

  publishMessage(severity: string, summary: string, detail: string) {
    this.message = {
      severity: severity,
      summary: summary,
      detail: detail,
    }
    this.messageService.add(this.message);
  }

  onReceiveMessage() {
    return this.message;
  }

  setRowImpegnate(rows) {
    this.rowsImpegnate = rows;
  }
  
  onReceiveRowsImpegnate() {
    return this.rowsImpegnate;
  }

  deleteRowsImpegnate(){
    this.rowsImpegnate = [];
  }

}