<div>
    <h3>Caricamenti in corso</h3>
    <div *ngFor="let upload of uploads; trackBy: trackByUploadId">
      <div>
        <div severity="secondary row" style="border-radius: 5px; width:100%;padding:0.5em; margin-top: 0.5em;margin-bottom: 0.5em;background-color: #f0f0f0!important;color:black!important"><p-tag class="col-3" [ngClass]="getStatusColor(upload.status)" [value]="getStatusName(upload.status)"></p-tag><span class="col-9" style="margin-left: 0.5em;margin-right: 0.5em;"><b>{{upload.documento.nomeFile}}</b></span></div>   
        <div class="progress-container" style="margin-top: 0.1em;">
          <div
            class="progress-bar"
            [style.width.%]="getCombinedProgress(upload)"
          ></div>
        </div>
        <p *ngIf="upload.message">{{ upload.message }}</p>
        <p><b>{{ getCombinedProgress(upload) | number: '1.0-0' }}%</b> completato</p>
        <hr />
      </div>
    </div>
    


  </div>
