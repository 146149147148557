import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsoleInterceptorService {
  private logs: string[] = [];

  constructor() {
    this.interceptConsole();
  }

  // Intercetta i messaggi della console
  private interceptConsole(): void {
    const originalLog = console.log;
    const originalWarn = console.warn;
    const originalError = console.error;

    console.log = (...args: any[]) => {
      this.addConsoleLog('LOG', args);
      originalLog.apply(console, args);
    };

    console.warn = (...args: any[]) => {
      this.addConsoleLog('WARN', args);
      originalWarn.apply(console, args);
    };

    console.error = (...args: any[]) => {
      this.addConsoleLog('ERROR', args);
      originalError.apply(console, args);
    };
  }

  // Aggiunge un log dalla console
  private addConsoleLog(level: string, args: any[]): void {
    var body = args?.toString();
    try {
      body = args.map(arg => JSON.stringify(arg)).join(' ')
    } catch(e) {
      
    }
    const message = `[${level}] ${new Date().toISOString()} - ${body}`;
    this.logs.push(message);
  }

  // Aggiunge un log per richieste HTTP
  public addHttpLog(message: string, data?: any): void {
    const logMessage = `[HTTP] ${new Date().toISOString()} - ${message}`;
    if (data) {
      const payload = JSON.stringify(data, null, 2);
      if (payload.length > 1000) {
        this.logs.push(`${logMessage}\nPayload (truncated): ${payload.substring(0, 1000)}...`);
      } else {
        this.logs.push(`${logMessage}\nPayload: ${payload}`);
      }
    } else {
      this.logs.push(logMessage);
    }
  }

  // Ritorna tutti i log
  public getLogs(): string[] {
    return this.logs;
  }

  // Cancella tutti i log
  public clearLogs(): void {
    this.logs = [];
  }
}
