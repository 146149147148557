import { Alarm, Annotations, QEHub } from "./qe";
import { Fonte } from "./fonte";

export class CUPResponse {
 success: boolean;
 dto: CUP;
}
export class CUP {
  id: number;
  codice: string;
  titolo: string;
  indirizzo: string;
  importo: number;
  descrizione: string;
  stato: string;
  dataCup?: Date;
  qEs?: QEHub[];
  annotations: Annotations[];
  alarms: Alarm[];
  storage: number
  allegato?: any;
  fonti: Fonte[]

  constructor(
    id: number,
    codice: string,
    titolo: string,
    descrizione: string,
    importo: number,
    indirizzo: string,
    stato: string,
    dataCup?: Date,
    allegato?: any,
    qes?: any,
    alarms?: Alarm[],
    annotations?: Annotations[]
  ) {
    this.id = id || 0;
    this.codice = codice || '';
    this.titolo = titolo || '';
    this.descrizione = descrizione || '';
    this.importo = importo || 0;
    this.indirizzo = indirizzo || '';
    this.stato = stato || '';
    this.dataCup = dataCup || null;
    this.allegato = allegato || null
    this.qEs = qes || [];
    this.alarms = alarms || [];
    this.annotations = annotations || [];
  }
}


