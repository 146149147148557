import {  Component, EventEmitter, Input, OnChanges,  Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from "primeng/api";
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { EntiService, Comune } from 'src/app/service/file';
import { EnteDto, License, LicenseType, UtenteAd } from 'src/app/model/ente';
import { ParametriS3, UserClientService } from 'src/app/service/user-client.service';
import { DialogModel } from '../../shared/rbk-modal/rbk-modal';
import { Ruolo, Utente } from 'src/app/model/utente';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';

@Component({
  selector: 'app-ente',
  templateUrl: './ente.component.html',
  styleUrls: ['./ente.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService]
})
export class EnteComponent {

  @Input() isInEdit: boolean;
  @Input() visible: boolean;
  @Input() enteDto: EnteDto;
  @Input() isAdmin: boolean;
  @Output() closeDialog = new EventEmitter<boolean>();
  licenseTypes: LicenseType[] = [
    {
      id: 2, 
      name: 'Standard',
      maxCUP: 100, 
      maxStorage: 1073741824,
      hasPrioritySupport: false
    },
    {
      id: 4, 
      name: 'Premium',
      maxCUP: 1000, 
      maxStorage: 10737418240,
      hasPrioritySupport: true

    }
  ]
  
  user: Utente;
  /**
   * Pipe utilizzata per i ruoli
   */
  public ruoloPipe = new RuoloPipe();
  /**
   * Ruoli per Gestione Utenti
   */
  utenteAdmin: UtenteAd;
  utentiAdmin: UtenteAd[] = [];

  enteForm: FormGroup;

  comune: Comune;
  comuni: Comune[];
  filteredComuni: Comune[] | undefined;
  /**
   * Filtro per la griglia
   */
  public selectedCity: Comune;

  utenteGroup = this.fb.group({
    userName: new FormControl(),
    emailConfirmed: new FormControl(),
    roles: new FormControl(),
    labelButton: new FormControl()
  })

  public isLocked: boolean = false;

  public hides3Url : boolean = true;
  public hides3AccessKey : boolean = true;
  public hides3SecretKey : boolean = true;

  public dialogModel: DialogModel;
  public visibleDialog: boolean = false;
  public isSuperAdmin: boolean = false;
  public isAdminCurrent: boolean = false;
  public labelButton: string = 'Invita';

  displayRenewDialog: boolean = false;
  renewLicenseForm: FormGroup;

  showDialogLicense(): void {
    if (!this.enteDto || !this.enteDto.licenses) return;
    this.sortedLicenses = [...(this.enteDto.licenses || [])].sort((a, b) =>
      new Date(b.startLicense).getTime() - new Date(a.startLicense).getTime()
    );
    this.licensesDialog = true;
  }
  licenseTypeFromId(id: number): string {
    if (this.licenseTypes.filter(x => x.id == id)?.length > 0) {
      return this.licenseTypes.filter(x => x.id == id)[0].name
    } else {
      return " - "
    }
  }
  licensesDialog: boolean = false;
  sortedLicenses: License[] = [];
  constructor(
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private entiServices: EntiService,
    private userClientService: UserClientService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {
    this.renewLicenseForm = this.fb.group({
      dataAttivazione: [null, Validators.required],
      dataProssimaScadenza: [null, Validators.required],
      licenseType: [null, Validators.required],
    });
    this.enteForm = this.fb.group({
      ente: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      selectedCity: new FormControl(null, Validators.required),
      provincia: new FormControl(null, Validators.required),
      regione: new FormControl(null, Validators.required),
      codice: new FormControl(null, Validators.required),
      codiceLicenza: new FormControl(null, Validators.required),
      dataAttivazione: new FormControl(null, Validators.required),
      dataProssimaScadenza: new FormControl(null, Validators.required),
      spazioAcquistatoS3: new FormControl('200Gb', Validators.required),
      spazioUtilizzatoS3: new FormControl('10Gb'),
      spazioDisponibileS3: new FormControl('90Gb'),
      maxDimFileDaCaricare: new FormControl('500Mb', Validators.required),
      s3Url: new FormControl(null, Validators.required),
      s3AccessKey: new FormControl(null, Validators.required),
      s3SecretKey: new FormControl(null, Validators.required),
      //isEnabled: new FormControl(),
      storage: new FormControl(),
      licenseType: new FormControl(null, Validators.required),
      users: this.fb.array([])
    })

    this.utenteGroup = this.fb.group({
      userName: new FormControl(),
      emailConfirmed: new FormControl(),
      roles: new FormControl('administrator'),
      labelButton: new FormControl('Invita')
    })
    this.user = this.userClientService.getUser();
  }

 generateRandomCode(): string {
    const segmentLength = 4;
    const totalSegments = 5;

    const randomSegment = () => {
        return Math.floor(1000 + Math.random() * 9000).toString();
    };

    const codeSegments = Array.from({ length: totalSegments }, randomSegment);
    return codeSegments.join('-');
  }
  setEnte(){
    this.selectedCity = this.comuni.find(c => c.denominazioneInItaliano === this.enteDto.city);
    this.enteForm = new FormGroup({
      ente: new FormControl(this.enteDto.ente),
      selectedCity: new FormControl(this.selectedCity),
      address: new FormControl(this.enteDto.address),
      provincia: new FormControl(this.enteDto.provincia),
      regione: new FormControl(this.enteDto.regione),
      codice: new FormControl(this.enteDto.codice),
      codiceLicenza: new FormControl(this.enteDto.codiceLicenza),
      dataAttivazione: new FormControl(new Date(this.enteDto.dataAttivazione)),
      dataProssimaScadenza: new FormControl(new Date(this.enteDto.dataProssimaScadenza)),
      spazioAcquistatoS3: new FormControl('200Gb'),
      spazioUtilizzatoS3: new FormControl('10Gb'),
      spazioDisponibileS3: new FormControl('90Gb'),
      maxDimFileDaCaricare: new FormControl('500Mb'),
      id: new FormControl(this.enteDto.id),
      s3Url: new FormControl(this.enteDto.s3Url),
      s3AccessKey: new FormControl(this.enteDto.s3AccessKey),
      s3SecretKey: new FormControl(this.enteDto.s3SecretKey),
      isEnabled: new FormControl(this.enteDto.isEnabled),
      storage: new FormControl(this.enteDto.storage),
      users: this.fb.array(this.enteDto.users),
      licenseType: new FormControl(this.enteDto.activeLicense?.licenseType, Validators.required)
    })

    this.enteForm.get('provincia').setValue(this.enteDto.provincia);
    this.enteForm.get('regione').setValue(this.enteDto.regione);

    this.enteFormArray.clear();
    this.enteDto?.users?.forEach(x => {
      const utenteGroup = this.fb.group({
        userName: new FormControl(x.userName),
        emailConfirmed: new FormControl(x.emailConfirmed),
        roles: new FormControl(x.roles[0]),
        labelButton: new FormControl('Aggiorna')
      });
      this.utenteGroup.get('userName').setValue(x.userName);
      this.utenteGroup.get('roles').setValue(x.roles[0]);
      
    this.enteFormArray.push(utenteGroup);

    })
    this.enteForm.disable();
  }
  async ngOnInit() {
    const comuni = await this.entiServices.getComuni();
    if(comuni) {
      this.comuni = comuni;
    }
    console.log(this.user)
    this.isSuperAdmin = this.user.systemRoles === new RuoloPipe().transform(Ruolo.SuperAdministrator);

    if (this.enteDto) {
      this.setEnte()
    } else {
      this.enteForm = new FormGroup({
        ente: new FormControl(null, Validators.required),
        address: new FormControl(null, Validators.required),
        selectedCity: new FormControl(null, Validators.required),
        provincia: new FormControl(null, Validators.required),
        regione: new FormControl(null, Validators.required),
        codice: new FormControl(null, Validators.required),
        codiceLicenza: new FormControl(null, Validators.required),
        dataAttivazione: new FormControl(null, Validators.required),
        dataProssimaScadenza: new FormControl(null, Validators.required),
        spazioAcquistatoS3: new FormControl('200Gb', Validators.required),
        spazioUtilizzatoS3: new FormControl('10Gb'),
        spazioDisponibileS3: new FormControl('90Gb'),
        maxDimFileDaCaricare: new FormControl('500Mb', Validators.required),
        s3Url: new FormControl(null, Validators.required),
        s3AccessKey: new FormControl(null, Validators.required),
        s3SecretKey: new FormControl(null, Validators.required),
        storage: new FormControl(),
        users: this.fb.array([]),
        licenseType: new FormControl(null, Validators.required)
      })
      this.enteForm.get('codiceLicenza').setValue(this.generateRandomCode());
      this.enteForm.get('selectedCity').valueChanges.subscribe(() => this.updateLocalizzazione());
      this.utenteGroup = new FormGroup({
        userName: new FormControl(),
        emailConfirmed: new FormControl(),
        roles: new FormControl('administrator'),
        labelButton: new FormControl('Invita')
      })
    }
    this.onDisableUsername();

  }

  public clearCityComune() {
    delete this.selectedCity;
  }

  gestioneIconEye(campo: string){
    if(this.enteForm.enabled){
      switch(campo){
        case 's3Url':
          this.hides3Url = !this.hides3Url;
          'pi-eye-slash'
          break;
        case 's3AccessKey':
          this.hides3AccessKey = !this.hides3AccessKey;
          break;
        case 's3SecretKey':
          this.hides3SecretKey = !this.hides3SecretKey;
          break;
      }
    }    
  }

  public async verificaParametris3(){
    console.log(this.enteForm);
    const paramentri = new ParametriS3();
    paramentri.accessKey = this.enteForm.controls['s3AccessKey'].value;
    paramentri.secretKey = this.enteForm.controls['s3SecretKey'].value;
    paramentri.url = this.enteForm.controls['s3Url'].value;
    const response = await this.userClientService.verificaS3(paramentri).toPromise();
    const esito = 'Esito verifica parametri:';
    this.dialogModel = {
      title: response && response.success ? "Successo" : "Attenzione",
      message: response && response.success ? `${esito} OK` : `${esito} KO`,
      btnConferma: "Chiudi",
      hasBtnAnnulla: false
    };
    
    this.visibleDialog = true;
  }

  enable() {
    this.enteForm.enable();
    this.enteForm.get('selectedCity').valueChanges.subscribe(() => this.updateLocalizzazione());
    this.enteForm.get('dataAttivazione').disable()
    this.enteForm.get('dataProssimaScadenza').disable()
    this.enteForm.get('codiceLicenza').disable()
    this.enteForm.get('licenseType').disable()
  }

  onDisableUsername() {
    if (this.isAdmin) {
      const countUsers = this.enteForm?.controls['users'].value?.length;
      const array = this.enteForm.get('users') as FormArray;
      if (countUsers > 0) {
        for(let i = 0; i < countUsers; i++) {
          const userName = array.controls[i].get('userName').value;
          if(userName && userName.toLowerCase() === this.user.userName.toLowerCase()) {
            array.controls[i].get('userName').disable();
            array.controls[i].get('emailConfirmed').disable();
            array.controls[i].get('roles').disable();
          } else {
            array.controls[i].get('userName').disable();
            array.controls[i].get('emailConfirmed').enable();
            array.controls[i].get('roles').enable();
          }
        }
      } 
    }
  }

  updateLocalizzazione() {
    const definizione = this.enteForm.get('selectedCity').value;
    this.enteForm.get('provincia').setValue(definizione.provincia);
    this.enteForm.get('regione').setValue(definizione.regione);
  }

  addUtente() {
    const utenteGroup = this.fb.group({
      userName: new FormControl(),
      emailConfirmed: new FormControl(),
      roles: new FormControl('administrator'),
      labelButton: new FormControl('Invita')
    });
    this.enteFormArray.push(utenteGroup)
  }

  get enteFormArray() {
    return this.enteForm.get('users') as FormArray;
  }


  filterComune(event) {
    let filtered: Comune[] = [];
    let query = event.query;

    for (let i = 0; i < (this.comuni as Comune[]).length; i++) {
      let comune = (this.comuni as Comune[])[i];
      if (comune.denominazioneInItaliano.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(comune);
      }
    }
    this.filteredComuni = filtered;
  }

  add() {
    this.enteDto = this.enteForm.value;
    this.enteDto.city = this.enteForm.value?.selectedCity?.denominazioneInItaliano;
    const codice = this.enteForm.value.codice
    this.enteDto.codice = codice.replace(/\s+/g, '_');
    this.spinner.show();
    this.userClientService.createClient(this.enteDto).subscribe({
      next: (resp: EnteDto) => {
        this.enteDto = resp
        console.log("new ente ", resp);
        this.spinner.hide();
        this.close(true);
      },
      error: (err) => {
        console.log("new ente error ", err)
        this.spinner.hide();
        this.close(false);
      }
    })
  }

  confirmDeleteFromLista(index: number, utenteGroup: AbstractControl) {
    const userName = utenteGroup.get('userName').value;
    if(!userName) {
      this.removeUtente(index);
    } else {
      this.confirmationService.confirm({
        message: "Sei sicuro di voler eliminare questo utente dalla lista?",
        header: "Eliminazione utente",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si",
        rejectLabel: "No, torna indietro",
        acceptButtonStyleClass: "p-button-success p-button-text",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.removeUtente(index);
          this.dissociate(utenteGroup.get('userName').value);
        }
      });
    }
  }

  removeUtente(index: number) {
    this.enteFormArray.removeAt(index);
  }

  confirm() {
    if (this.enteFormArray.value.length === 0) {
      this.confirmationService.confirm({
        message: "Stai salvando un ente senza aver aggiunto alcun utente admin. Procedere?",
        header: "Nuovo ente",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si",
        rejectLabel: "No, torna indietro",
        acceptButtonStyleClass: "p-button-success p-button-text",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.add();
        }
      });
    } else {
      console.log(this.enteFormArray.value)
      let listaUtenti = this.enteFormArray.value;
      this.spinner.show();
      for (let utente of listaUtenti) {
        if (utente.userName) {
          this.associate(utente.userName, false)
          console.log(utente)
        }
      }
      this.spinner.hide();
      this.add();
    }
  }

  confirmSave() {
    if (this.enteFormArray.value.length === 0) {
      this.confirmationService.confirm({
        message: "Stai salvando un ente senza aver aggiunto alcun utente admin. Procedere?",
        header: "Modifica ente",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si",
        rejectLabel: "No, torna indietro",
        acceptButtonStyleClass: "p-button-success p-button-text",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.save();
        }
      });
    } else {
      let listaUtenti = this.enteFormArray.value;
      if (listaUtenti.filter(x => x.roles === null).length === 0) {
        this.save();
      } else {
        this.confirmationService.confirm({
          message: "Gli admin a cui non è stato inviato l'invito non verranno associati a questo ente. Procedere con il salvataggio?",
          header: "Modifica ente",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Si",
          rejectLabel: "No, torna indietro",
          acceptButtonStyleClass: "p-button-success p-button-text",
          rejectButtonStyleClass: "p-button-text",
          accept: () => {
            this.save();
          }
        });
      }
    }
  }

  save() {
    this.enteDto = this.enteForm.value;
    this.spinner.show();

    // Fabio;
    this.enteDto?.users?.forEach(x => {
      console.log(x.userName, x.roles);
    });

    this.enteDto.city = this.enteForm.value?.selectedCity?.denominazioneInItaliano;
    const codice = this.enteForm.value.codice;
    this.userClientService.editClient(this.enteDto).subscribe({
      next: (resp: EnteDto) => {
        this.spinner.hide();
        this.close(true);
      },
      error: (err) => {
        this.spinner.hide();
        this.close(false);
      }
    })
  }

  associate(utente: AbstractControl, bool) {
    if (bool) {
      this.spinner.show();
    }
    if(utente) {
      const userName = utente.get('userName').value;
      this.enteDto = this.enteForm.value;
      const isAdmin: boolean = this.enteDto?.users?.find(x=> x.userName === userName)?.roles === "administrator" || false;
      this.userClientService.associateUser(userName, this.enteForm.value.codice, isAdmin).subscribe({
        next: (resp) => {
          console.log(resp)
          this.messageService.add({
            severity: "info",
            summary: "Utente associato",
            detail: "Utente associato",
          });
          let user = this.enteForm.value.users.find(x => x.userName === userName)
          user.roles = 'admin';
        },
        error: (err) => {
          console.log(err)
          this.messageService.add({
            severity: "error",
            summary: "Utente non associato",
            detail: "Errore: " + err,
          });
        }
      });
      this.spinner.hide();
    }
  }

  dissociate(username: string) {
    this.spinner.show()
    this.userClientService.dissociateUser(username, this.enteForm.value.codice).subscribe({
      next: (resp) => {
        this.spinner.hide();
        this.messageService.add({
          severity: "info",
          summary: "Utente dissociato",
          detail: "Utente dissociato",
          sticky: true
        });
      },
      error: (err) => {
        this.spinner.hide();
        this.messageService.add({
          severity: "error",
          summary: "Utente non dissociato",
          detail: "Errore: " + err,
          sticky: true
        });
      }
    })
  }

  close(update: boolean) {
    this.visible = false;
    this.enteDto = undefined;
    this.closeDialog.emit(update);
  }
  // Mostra il dialog di rinnovo
  openRenewDialog(): void {
    // Inizializza il form con i valori correnti (se disponibili)
    this.renewLicenseForm.patchValue({
      dataAttivazione: new Date(), // Puoi usare un valore predefinito
      dataProssimaScadenza: new Date(), // Puoi usare un valore predefinito
      licenseType: null, // Puoi usare un valore predefinito
    });
    this.displayRenewDialog = true;
  }

  // Chiude il dialog di rinnovo
  closeRenewDialog(): void {
    this.displayRenewDialog = false;
  }

  // Salva i nuovi valori
  renewLicense(): void {
    if (this.renewLicenseForm.valid) {
      var license : License  = {
        endLicense: this.renewLicenseForm.value.dataProssimaScadenza,
        licenseCode: this.enteDto.codiceLicenza,
         licenseType: this.renewLicenseForm.value.licenseType,
         startLicense: this.renewLicenseForm.value.dataAttivazione
      }
      this.closeRenewDialog();
      this.spinner.show();
      this.userClientService.renewSubscription(this.enteDto.id!, license).subscribe({
        next: (value) => {
          this.enteDto = value
          this.setEnte();
          this.spinner.hide();
        },
        error: (err) => {
          console.log(err)
          this.spinner.hide();
        }

      })
    }
  }

}
