import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AreaDto, AreaDtoResponse, Login, LoginResponse, SearcherUserDto, UserAuthDto, UserAuthDtoResponse, UserRoleAuthDto, UserRoleAuthDtoResponse, Utente } from '../model/utente';
import { EnteDto, License } from '../model/ente';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BaseHttpService } from '../basehttp.service';

export class ParametriS3 {
  public accessKey:string; 
  public secretKey: string;
  public url: string
}

@Injectable({
  providedIn: 'root'
})
export class UserClientService {

  BASE_URL = '';
  LOGIN_URL = 'api/Login';
  USER_URL = 'api/Users';
  USERAUTH_URL = 'api/UserAuth';
  CLIENT_URL = 'api/Client';

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private jwtHelper: JwtHelperService,
    private http: HttpClient,
    private apiClient: BaseHttpService) {
    this.BASE_URL = baseUrl;
    this.LOGIN_URL = baseUrl + this.LOGIN_URL;
    this.USER_URL = baseUrl + this.USER_URL;
    this.CLIENT_URL = baseUrl + this.CLIENT_URL;
    this.USERAUTH_URL = baseUrl + this.USERAUTH_URL;
  }

  login(loginRequest: Login): Observable<any> {
    return this.http.post<any>(this.LOGIN_URL, loginRequest, {
      observe: "response"
    })
  }
  
  logoutAsync(userId: string): Observable<boolean> {
    localStorage.removeItem("jwt");
    return this.http.post<boolean>(`${this.LOGIN_URL}/Logout?userId=${userId}`, {
      observe: "response"
    });
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem("jwt");

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      console.log(this.jwtHelper.decodeToken(token));
      return true;
    }
    return false;
  }

  saveToken(userLogin: LoginResponse) {
    console.log(userLogin);
    localStorage.setItem("jwt", userLogin.accessToken)
  }
  logout() {
    localStorage.removeItem("jwt")
  }


  createClient(ente: EnteDto): Observable<EnteDto> {
    return this.http.post<EnteDto>(this.CLIENT_URL, ente);
  }
  editClient(ente: EnteDto): Observable<EnteDto> {
    return this.http.put<EnteDto>(this.CLIENT_URL, ente);
  }
  getClient(): Observable<EnteDto[]> {
    return this.http.get<EnteDto[]>(`${this.CLIENT_URL}`);
  }
  getClientById(id: string): Observable<EnteDto> {
    return this.http.get<EnteDto>(`${this.CLIENT_URL}/${id}`)
  }
  renewSubscription(id: string, license: License): Observable<EnteDto> {
    return this.http.post<EnteDto>(`${this.CLIENT_URL}/RenewSubscription/${id}`, license)
  }
  deleteClientById(id: string): Observable<EnteDto> {
    return this.http.delete<EnteDto>(`${this.CLIENT_URL}/${id}`)
  }

  verificaS3(parametriS3: ParametriS3): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('accessKey', parametriS3?.accessKey?.toString());
    formData.append('secretKey', parametriS3.secretKey.toString());
    formData.append('url', parametriS3.url);
    return this.apiClient.postForm(formData, `${this.CLIENT_URL}/VerificaS3`);
  }




  changePass(username: string, password: string, newpassword: string): Observable<any> {
    return this.http.post<any>(`${this.USER_URL}/ChangePassword`, { userName: username, password: password, newPassword: newpassword });
  }

  resetPass(username: string): Observable<any> {
    return this.http.post<any>(`${this.USER_URL}/ResetPassword`, { userName: username });
  }

  confirmResetPass(username: string, password: string, code: string): Observable<any> {
    return this.http.post<any>(`${this.USER_URL}/ConfirmResetPassword`, { userName: username, password: password, code: code });
  }

  getUser(): Utente | null {
    const token = localStorage.getItem("jwt");
    const infoRuolo = localStorage.getItem("infoRuolo");
    try {
      if (token && !this.jwtHelper.isTokenExpired(token)) {
        let user = this.jwtHelper.decodeToken(token)
        let firstName: string = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname']
        let roles: string | null = user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        let lastName: string = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname']
        let name: string = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']
        let upn: string = user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn']
        let clientId: string = user['client_id']

        return {
          firstName: firstName,
          roles: infoRuolo,
          systemRoles: roles,
          lastName: lastName,
          userName: name,
          id: upn,
          fiscalCode: '',
          phoneNumber: '',
          mobilePhoneNumber: '',
          clientId: clientId,
          city: '',
          createdAt: new Date(),
          isLocked: false,
          address: '',
          province: '',
          qualification: ''

        }
      }
    } catch (ex) {
      localStorage.removeItem("jwt")
      console.log(ex)
    }
    return null
  }

  getUsers(clientId: string) : Observable<Utente[]>{
    return this.http.get<Utente[]>(`${this.USER_URL}/client/${clientId}`)
  }
  createUser(utente: Utente, isAdmin: boolean, clientId: string): Observable<any> {
    utente.roles = isAdmin ? "administrator" : "user";
    return this.http.post(`${this.USER_URL}?clientId=${clientId}`, utente);
  }
  associateUser(username: string, clientId: string, isAdmin: boolean): Observable<any> {
    return this.http.get(`${this.USER_URL}/associate/${username}/${clientId}?isAdmin=${isAdmin}`)
  }
  dissociateUser(username: string, clientId: string): Observable<any> {
    return this.http.get(`${this.USER_URL}/dissociate/${username}/${clientId}`)
  }

  // Api per le Autorizzazioni e i Permessi
  getArea(): Observable<AreaDtoResponse> {
    return this.http.get<AreaDtoResponse>(`${this.USERAUTH_URL}/GetAreas`);
  }

  getUserAuth(searcherUser: SearcherUserDto): Observable<UserRoleAuthDtoResponse> {
    return this.http.post<UserRoleAuthDtoResponse>(`${this.USERAUTH_URL}/SearcherUserAuth`, searcherUser);
  }

  addOrUpdateUserAuth(user: UserAuthDto): Observable<UserAuthDtoResponse> {
    return this.http.post<UserAuthDtoResponse>(`${this.USERAUTH_URL}/AddOrUpdateUserAuth`, user);
  }
}
