import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authorization-service/auth.service';
import { AuthorizeService } from './authorization-service/authorize.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeInterceptor implements HttpInterceptor {
  [x: string]: any;
  constructor(private authorize: AuthorizeService,
    private authenticationService: AuthenticationService) { }

  getAccessToken() : string | null {
    return localStorage.getItem("jwt") ;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //return this.processRequestWithToken(this.getAccessToken(), req, next);
      //Aggiunto fabio 11.05.2021 San Fabio
      let user = this.authenticationService.currentUserValue;
      if (req.url.startsWith("https://rub")) {
        return next.handle(req);
      }
      const t = this.getAccessToken();
      if (user != null) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${user.token}`
          }
        });
        return next.handle(req);
      }
      return this.processRequestWithToken(this.getAccessToken(), req, next);
  }

  private processRequestWithToken(token: string | null, req: HttpRequest<any>, next: HttpHandler) {
    if (!!token) {//&& this.isSameOriginUrl(req)) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req);
  }

  private isSameOriginUrl(req: any) {
    // It's an absolute url with the same origin.
    if (req.url.startsWith(`${window.location.origin}/`)) {
      return true;
    }

    // It's a protocol relative url with the same origin.
    // For example: //www.example.com/api/Products
    if (req.url.startsWith(`//${window.location.host}/`)) {
      return true;
    }

    // It's a relative url like /api/Products
    if (/^\/[^\/].*/.test(req.url)) {
      return true;
    }

    // It's an absolute or protocol relative url that
    // doesn't have the same origin.
    return false;
  }
}
function mergeMap(arg0: (token: any) => Observable<HttpEvent<any>>): any {
  throw new Error('Function not implemented.');
}

