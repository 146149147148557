import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ConfirmEventType,
  ConfirmationService,
  MessageService,
} from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import {
  AreaDocumentale,
  Documento,
  DocumentoComplete,
  DocumentoKeyValueUpdate,
  DocumentoSearch,
  ModelloDocumentale,
  TipoEntita,
} from 'src/app/model/documento';
import { QE, QEMainBase } from 'src/app/model/qe';
import { Scenario } from 'src/app/model/scenari';
import { TipoEntitaPipe } from 'src/app/pipe/tipo-entita-pipe';
import { DocumentiService } from 'src/app/service/documenti.service';
import { ScenarioService } from 'src/app/service/scenario.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MimeTypePipe } from 'src/app/pipe/format-size-pipe';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedService } from 'src/app/service/shared.service';
import {
  DocumentiDaArchiviareBrowse,
  DraftSearch,
} from '../documenti-sospesi/documenti-sospesi';
import { CUP } from 'src/app/model/cup';
@Component({
  selector: 'rbk-upload-file',
  templateUrl: './rbk-upload-file.html',
  styleUrls: ['./rbk-upload-file.scss'],
  providers: [ConfirmationService, MessageService, PdfViewerComponent],
})
export class RbkUploadFileComponent implements OnInit, OnChanges {
  /**
   * Scenario di provenienza
   */
  @Input() public isFrom: Scenario;

  @Input() public formGroup: FormGroup;

  /**
   * Id del Cup
   */
  @Input() public idCup: number;
  /**
   * Cup
   */
  @Input() public cup: CUP;

  /**
   * Nome del modello documentale
   */
  @Input() public nomeModello: string;
  /**
   * La tipologia del documento
   */
  @Input() public tipologia: string;
  /**
   * La data del caricamento del documento
   */
  @Input() public key_2: Date;
  /**
   * Numero dello scenario di riferimento
   */
  @Input() public key_3: string;
  /**
   * Descrizione del documento
   */
  @Input() public key_4: string;
  /**
   * Descrizione del documento
   */
  @Input() public key_5: string;
  /**
   * Tipo atto del documento
   */
  @Input() public key_6: string;
  /**
   * Beneficiario dello scenario di riferimento
   */
  @Input() public key_7: string;
  /**
   * P.I./C.I. dello scenario di riferimento
   */
  @Input() public key_8: string;
  /**
   * Id dell'entità di riferimento
   */
  @Input() public entityID: number;
  /**
   * Il tipo di entità di riferimento
   */
  @Input() public entityTipo: TipoEntita;
  /**
   * Note dello scenario di riferimento
   */
  @Input() public note: string;

  @Input() public beneficiarioString: string;

  @Input() public pIva_cFiscaleString: string;
  /**
   * Il pulsante è disabilitato se alcune specifiche
   * non sono rispettate
   */
  @Input() public isButtonAddDisabled: boolean;

  @Input() public showButton: boolean;

  @Input() public checkDocumentiCaricati: boolean;

  @Input() public documentoID: number;

  /**
   * Se true non fa visualizzare il pulsante per l'inserimento
   * del file
   */
  @Input() public showBtnAggiungiDocumenti: boolean = true;

  @Input() public basicForm: boolean;
  /**
   * Se true fa visualizzare il dialog per la conferam dei dati
   */
  @Input() public isShowDialogLoadDoc: boolean;
  /**
   * Qe attuale uguale all'ultimo qe storico
   */
  @Input() public qeAttuale: QE;
  /**
   * La lista dei qe storici
   */
  @Input() public qeStorico: QEMainBase[];
  /**
   * Indica se il Qe è quello attuale
   */
  @Input() public isQeAttuale: boolean = false;
  /**
   * Ricarica la griglia dei documenti se il valore cambia
   */
  @Input() public boolDisabled: boolean;

  @Input() public isRefreshGrid: boolean;
  /**
   * Se true apre la modale per caircare i documenti
   */
  @Input() public visibleFile: boolean = false;
  /**
   * Edit il qe corrente
   */
  @Input() public editCurrent: boolean;
  /**
   * Indica se la form è in edit
   */
  @Input() public isEditForm: boolean = true;
  /**
   * Indica se lo storico è in edit
   */
  @Input() public isEditQeStorico: boolean;
  /**
   * Salva i dati della form corrente se l'id non esiste
   */
  @Output() public onSaveDatiForm = new EventEmitter<void>();
  /**
   * Evento che si verifica al complete di un upload
   */
  @Output() public onCompleteUpload = new EventEmitter<void>();
  /**
   * Eventi che si verifica al click su Annulla
   */
  @Output() public onAbort = new EventEmitter<void>();
  /**
   * Evento che si verifica alla chiusura del form di caricamento
   */
  @Output() public onCloseFormCaricaFile = new EventEmitter<void>();

  @Output() public finishUpload = new EventEmitter<boolean>();
  /**
   * Se il file è stato selezionato
   */
  @Output() public onSelectFile = new EventEmitter<File>();

  /**
   * Emit per cambiare il valore e non essere una form di Aggiuta //// §§§ MariaLusia
   */
  @Output() public changeIsAddForm = new EventEmitter<boolean>();
  /**
   * Emit per cambiare il valore e non essere una form di Edit
   */
  @Output() public changeIsEditForm = new EventEmitter<boolean>();

  public visible: boolean = false;

  /**
   * Se ppreme btn per caricare ile da cartella monitorata
   */
  public showDocumentCM: boolean = false;
  selectedDocumento: DocumentiDaArchiviareBrowse;
  /**
   * Flag, includi senza Cup
   */
  public includiSenzaCup: boolean = false;
  /**
   * Lista dei documenti recuperati da lavorare
   */
  public documentBrowse: DocumentiDaArchiviareBrowse[] = [];
  /**
   * Paginazione Griglia Documenti da Cartella Monitorata
   */
  public first = 0;
  public rows = 10;
  /**
   * Tutti i documenti dalla cartella monitorata
   */
  public allListaDocumenti: DocumentiDaArchiviareBrowse[] = [];
  /**
   * Spinner per il caricamento dei documenti da cartella monitarata
   */
  public caricamentoGrid: boolean = false;
  /**
   * Modello documentale del Cup
   */
  public modelloDocumentale: ModelloDocumentale;
  /**
   * La pipe per il tipo di entità
   */
  public tipoEntitaPipe = new TipoEntitaPipe();
  /**
   * Lista dei file associati allo scenario di riferimento
   */
  public listFiles: Documento[] = [];
  /**
   * Se true indica che si sta caricando un file
   */
  public isAddFile: boolean = false;
  /**
   * Indica il titolo della modale in base
   * a se è un add/visualizzazione
   */
  public headerDialog: string = '';
  /**
   * Se true apre la modale per confermare i dati inseriti
   */
  public visibleDialogLoadDoc: boolean = false;
  /**
   * Indica il messaggio da visualizzare nel dialog
   */
  public messageDialog: string =
    'Per caricare il documento è necessario salvare i dati inseriti. Procedo?';
  /**
   * Se true apre il popup per cancellare un file
   */
  public isShowDialogDeleteDoc: boolean = false;
  /**
   * Url del documento
   */
  public urlBlob: string;

  /**
   * Url del documento
   */
  public urlSafe: SafeResourceUrl;
  /**
   * Documento che arriva dalla selezione
   */
  public documentoView: DocumentoComplete;
  /**
   * Documento a cui cambiare il nome file
   */
  public documentoChangeNomeFile: Documento;
  /**
   * File selezionato nella griglia
   */
  public selectedFile: Documento;
  public clonedFiles: { [s: string]: Documento } = {};
  /**
   * Pipe per conventire l'estensione del file con il tipo del file
   */
  private _mimeTypePipe = new MimeTypePipe();

  public listaFileTemporanea: File[] = [];

  public pdfSrc;

  public nomeFileAnteprimaDocumento: string;

  public isPdf: boolean;

  public indexRinominafile: number = -1;

  public indexRinominafileAdd: number = -1;

  public changedName: string = '';

  public filePreviewSelected: File;

  public filePreviewCartellaMonitorata: File;

  public caricamentoFileInCorso: boolean = true;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private documentiService: DocumentiService,
    private scenarioServices: ScenarioService,
    private spinner: NgxSpinnerService,
    private _sharedServices: SharedService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    // Salvataggio del documento
    const isEditFormChange = changes && changes['isEditForm'];
    // Utilizzato da cartella monitorata per visualizzare l'anteprima
  }

  public async ngOnInit() {
    if (this.documentoID && this.isFrom === Scenario.Documento) {
      this.entityID = this.documentoID;
    }

    // const isAnteprimaDocumento = this._sharedServices.isAnteprimaDocumentoCartellaMonitorata();
    // const idDocumentoCartellaMonitorata = this._sharedServices.getDocumentoIdCartellaMonitorata();
    // if(idDocumentoCartellaMonitorata && isAnteprimaDocumento){
    // 	// Se provengo da cartella monitorata, mostro l'anteprima del documento selezionato
    // 	this.showDialog(false);
    // 	this.showBtnAggiungiDocumenti = false;
    // 	await this.loadFileFromServer(idDocumentoCartellaMonitorata);
    // 	this.onSelectFile.emit(this.filePreviewCartellaMonitorata);
    // } else {
    // 	// recupero la lista dei file
    // 	await this.getListNomeFile();
    // }
  }

  /**
   * Apre la modale per verificare se è possibile caricare
   * i documenti
   */
  public showDialogLoadDoc() {
    this.showDialog(true);
  }

  /**
   * Apre la modale per caricare i documenti
   */
  public showDialog(isAddFile: boolean) {
    this.visible = true;
    this.isAddFile = isAddFile;
    this.headerDialog = isAddFile ? 'Carica File' : 'Lista File';
    this.pdfSrc = undefined;
    this.isPdf = false;
    this.urlSafe = undefined;
  }

  public onCloseDialog() {
    this.visible = false;
    this.urlBlob = undefined;
    this.pdfSrc = undefined;
    this.isPdf = false;
    this.urlSafe = undefined;
    // if (this.labelButtonCloseDialog === 'Fatto') {
    // 	this.onCloseFormCaricaFile.emit();
    // 	this.onAbort.emit();
    // }
  }

  /**
   * Filtra la griglia in base ai modelli selezionati
   * @param selectedModelli  modelli selezionati nel filtro
   */
  public async filterBrowse() {
    this.caricamentoGrid = true;
    this.documentBrowse = [];
    const filtri: DraftSearch = new DraftSearch();
    filtri.codiceCup = (this.cup && this.cup?.codice) || '';
    filtri.includiSenzaCup = !!this.includiSenzaCup;
    filtri.pageIndex = 0;
    filtri.pageSize = this.rows; /// !!! Alessia ToDo da gestire la paginazione
    const response = await this.documentiService
      .getSearchDraftDocument(filtri)
      .toPromise();
    if (response) {
      // this.documentBrowse = response;
    }
    this.caricamentoGrid = false;
  }

  /**
   * Prendi i carico il documento selezionato da cartella onitorata
   */
  public showDocumentiCM() {
    this.showDocumentCM = true;
    this.filterBrowse();
  }

  /**
   *
   */
  public async prendiInCaricoDocumento(documento) {
    this._sharedServices.setAnteprimaDocumentoCartellaMonitorata(
      true,
      documento.id
    );
    this.showDialog(false);
    await this.loadFileFromServer(documento.id);
    this.showDocumentCM = false;
    this.onSelectFile.emit(this.filePreviewCartellaMonitorata);
  }

  /**
   * Recupera estenzione dal filename
   */
  public getExtensioneFile(filename: string) {
    const re = /(?:\.([^.]+))?$/;
    return (re.exec(filename) && re.exec(filename)[1]) || 'pdf';
  }

  /**
   * Recupera estenzione dal filename
   */
  public getNomeFile(filename: string) {
    return filename.split('.')[0];
  }

  /**
   * Preview del file selezionato
   */
  public async previewFile(file: File, fileUploadedEvent?: any) {
    this.spinner.show();
    this.filePreviewSelected = file;
    // Alla selezione, carico l'intera lista di file
    if (fileUploadedEvent) {
      this.listaFileTemporanea = fileUploadedEvent.currentFiles;
    }

    // Anteprima del file selezionato
    if (file) {
      // Verifica se il file è un PDF
      this.isPdf = file.type === 'application/pdf';
      // Leggi il file e genera un'anteprima
      await this.readAsync(file);
      this.onSelectFile.emit(file);
    }
    this.spinner.hide();
  }

  private async readAsync(file: File) {
    this.filePreviewSelected = file;
    return new Promise<void>((resolve) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        // Ottieni l'URL dell'anteprima
        this.pdfSrc = e.target.result;
        this.nomeFileAnteprimaDocumento = file.name;
        resolve();
      };
      reader.readAsDataURL(file);
    });
  }

  /**
   * Preview dei documenti recuperati dal server tramite id
   * @param documentoId
   */
  public async loadFileFromServer(documentoId: number) {
    this.caricamentoFileInCorso = true;
    delete this.documentoView;
    const isAnteprimaDocumento =
      this._sharedServices.isAnteprimaDocumentoCartellaMonitorata();
    const response = isAnteprimaDocumento
      ? await this.documentiService
          .getDocumentoArchiviato(documentoId)
          .toPromise()
      : await this.documentiService.getDocumento(documentoId).toPromise();
    if (response && response.success) {
		this.documentoView = response.dto;
    } else {
		console.log('500');
	}
    if (this.documentoView) {
  
    }
  }


  /**
   * Update delle chiavi del documento
   */
  public async onUpdateKeyDocumento(documento: Documento) {
    if (documento) {
      if (!this.nomeModello && this.isFrom === Scenario.Documento) {
        this.nomeModello = this.formGroup.value.nomeModello;
        this.entityTipo = this.scenarioServices.getTipologia(
          this.nomeModello,
          this.formGroup
        );
      }
      if (this.nomeModello !== 'Quadro Economico') {
        this.entityTipo = this.scenarioServices.getTipologia(
          this.nomeModello,
          this.formGroup
        );
      }
      if (this.entityTipo) {
        var tipologia = this.tipoEntitaPipe.transform(this.entityTipo);
      }
      const docKeyValue = this.scenarioServices.getDocKeyValueByModello(
        this.isFrom,
        this.formGroup,
        tipologia,
        this.nomeModello,
        this.beneficiarioString,
        this.pIva_cFiscaleString
      );
      console.log(docKeyValue);

      const documentoKeyValueUpdate: DocumentoKeyValueUpdate = {
        entita: this.entityTipo,
        entitaID: this.entityID,
        chiaviModello: docKeyValue,
        documentoID: documento.keys.idDocumento
      };

      const result = await this.documentiService
        .updateKeyDocumento(documentoKeyValueUpdate)
        .toPromise();
      // await this.getListNomeFile();
    }
    this.isEditForm = false;
    this.changeIsEditForm.emit(this.isEditForm);
  }

  /**
   * Rimuove il file selezionato
   * @param file
   */
  public removeFile(file: File, uploader: FileUpload) {
    const index = uploader.files.indexOf(file);
    uploader.remove(null, index);
    this.listaFileTemporanea.slice(index, 1);
    delete this.pdfSrc;
    delete this.urlSafe;
    this.isPdf = false;
    if (this.listaFileTemporanea.length === 0) {
      this.onSelectFile.emit(undefined);
    }
  }

  async filtriDocumenti() {
    // const modello = await this.getModello();
    let filterDocumentiCup = new DocumentoSearch();
    filterDocumentiCup.cupId = this.idCup;
    filterDocumentiCup.entityId = this.entityID;
    filterDocumentiCup.entityTipo = this.entityTipo;
    // filterDocumentiCup.modelloIDs = !!modello ? [modello?.id]: [];

  //  if (
  //     this.isFrom === Scenario.Fonte ||
  //     this.isFrom === Scenario.Incasso
  //   ) {
  //     this.sezioni = AreaDocumentale.FonteFinanaziamento;
  //   } else {
  //     // !!!! Alessia da verificare con chi l'ha fatto!!!!
  //     // if (this.isFrom < Scenario.Annotazione || this.isFrom === Scenario.Documento || this.isFrom === Scenario.QuadroEconomico) {
  //     filterDocumentiCup.area = AreaDocumentale.Esecuzione;
  //   }
    return filterDocumentiCup;
  }

  /**
   * Recupera i file associati al cup
   * @param idCup id del Cup
   */
  public async getListNomeFile() {
    this.caricamentoFileInCorso = true;
    this.listFiles = [];
    let filterDocumentiCup = await this.filtriDocumenti();
    if (filterDocumentiCup && filterDocumentiCup.cupId) {
      const resultFilteredBrowse = await this.documentiService
        .filterDocumento(filterDocumentiCup, [])
        .toPromise();
      if (resultFilteredBrowse && resultFilteredBrowse.length > 0) {
        if (this.entityTipo === TipoEntita.QuadroEconomicoStorico) {
          const docQeStorico = resultFilteredBrowse.filter(
            (x) => x.entityID === this.qeAttuale.qeMainId
          );
          if (docQeStorico && docQeStorico.length > 0) {
            this.listFiles = docQeStorico;
          }
        } else if (this.documentoID) {
          this.listFiles = resultFilteredBrowse.filter(
            (x) => x.id === this.documentoID
          );
          await this.loadFileFromServer(this.documentoID);
        } else if (
          this.entityID &&
          this.entityTipo !== TipoEntita.QuadroEconomicoBozza
        ) {
          /// !!! Alessia qui vengono filtrati e aggiunti i file alla lista se non sono il QEStorico
          this.listFiles = resultFilteredBrowse.filter(
            (x) => x.entityID === this.entityID
          );
        }

        // Mostro l'anteprima del primo documento caricato, o dell'unico se nella lista è presente un solo documento
        if (this.listFiles?.length >= 1) {
          await this.loadFileFromServer(this.listFiles[0].id);
        } else {
          this.caricamentoFileInCorso = false;
        }
      }
    }
    this.caricamentoFileInCorso = false;
    console.log('DOCUMENTI listFiles ', this.listFiles);
  }

  /**
   * Scarica il file selezionato
   * @param documentoId id del documento
   * @param nomeFile nome del documento
   */
  async downloadFile(documentoId: number, nomeFile: string) {
    this.spinner.show();
    let documento: DocumentoComplete;
    const response = await this.documentiService
      .getDocumento(documentoId)
      .toPromise();
    if (response && response.success) {
      documento = response.dto;
    } else {
      console.log('500');
    }

    let mediaType = '';
    if (documento.mediaType === 'application/pkcs7-mime') {
      mediaType = 'application/pdf';
    } else {
      mediaType = documento.mediaType;
    }
    if (documento) {
     
    }

    this.spinner.hide();
  }

  /**
   * Metodo chiamato al change del nome file
   * @param documento documento in cui cambiare il nomefile
   */
  onChangeNomeFile(documento: Documento, nomeFile: string) {
    this.documentoChangeNomeFile = documento;
  }

  /**
   * Rinomina del Nome File dall'upload
   */
  renameFile(index: number) {
    const fileDaSostituire = this.listaFileTemporanea[index];
    const fileNameDaSostituire =
      this.changedName !== '' ? this.changedName : fileDaSostituire.name;

    const re = /(?:\.([^.]+))?$/;
    const extension = re.exec(fileDaSostituire.name)[1];
    const mediaType = this._mimeTypePipe.transform(extension);

    // Rinomina del nome file
    var blob = fileDaSostituire.slice(0, fileDaSostituire.size, mediaType);
    const fileName = `${fileNameDaSostituire}.${extension}`;
    const file = new File([blob], fileName, { type: mediaType });

    this.listaFileTemporanea.splice(index, 1, file);

    this.indexRinominafileAdd = -1;
    this.changedName = '';
  }

  /**
   * Rinomina del Nome File dalla lista dei caricati
   */
  async renameFileLista(index: number) {
    const fileDaSostituire = this.listFiles[index];
    const re = /(?:\.([^.]+))?$/;
    const extension = re.exec(fileDaSostituire.nomeFile)[1];

    if (this.changedName !== '') {
      fileDaSostituire.nomeFile = this.changedName;
      const nomeFileChanged = this.changedName
        .concat('.' + extension)
        .replace(/\.\./g, '.');
      this.documentiService
        .updateNomeFile(fileDaSostituire.id, nomeFileChanged)
        .toPromise();
    }

    this.listFiles.splice(index, 1, fileDaSostituire);
    this.indexRinominafile = -1;
    this.changedName = '';
    this.getListNomeFile();
  }

  annullaRenameLista(index: number) {
    const fileDaSostituire = this.listFiles[index];
    this.listFiles.splice(index, 1, fileDaSostituire);
    this.indexRinominafile = -1;
    this.indexRinominafileAdd = -1;
  }

  annullaRename(index: number) {
    this.indexRinominafileAdd = -1;
  }

  /**
   * Cancella il file
   * @param idFile id del file da cancellare
   */
  public async deleteFile(idFile: number) {
    console.log('---- spinner deletefile rbk-upload-file ----');
    this.spinner.show();
    const resultDelete = await this.documentiService
      .deleteDocumento(idFile)
      .toPromise();
    if (resultDelete) {
      this.messageService.add({
        severity: 'success',
        summary: 'Successo',
        detail: 'Il file è stato cancellato con successo!',
      });
      await this.getListNomeFile();
      this.visible = false;

      this.spinner.hide();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Attenzione',
        detail: 'Non è stato possibile cancellare il file',
      });

      this.spinner.hide();
    }
  }

  public showDialogDeleteFile(idFile: number) {
    this.isShowDialogDeleteDoc = true;
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler cancellare questo file?',
      header: 'Cancella File',
      icon: 'pi pi-exclamation-triangle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      dismissableMask: false,
      accept: async () => {
        this.isShowDialogDeleteDoc = false;
        await this.deleteFile(idFile);
      },
      reject: (type: ConfirmEventType) => {
        if (type === ConfirmEventType.REJECT) {
          this.isShowDialogDeleteDoc = false;
        }
      },
    });
  }
}
